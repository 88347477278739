// tickerActions.ts

import { PositionsService } from 'src/services/positions';
import {
  GET_POSITIONS_FAILURE,
  GET_POSITIONS_REQUEST,
  GET_POSITIONS_SUCCESS,
  UPDATE_ALL_POSITION,
  UPDATE_POSITIONS,
} from '../constants/positions';
import { PositionsGetAllParams, PositionsParams } from 'src/services/params-type';
import { RootStore } from '..';
import { IPositions } from '../reducers/positions';
import {
  RECORDS_PER_PAGE,
  TypeTrade,
} from 'src/pages/exchange/components/03.order-history/components/PostitionTab/consts';

export const getContractType = () => {
  // if (!window.location.pathname.includes('orders')) {
  //   return window.location.pathname.includes(TypeTrade.COIN_M) ? TypeTrade.COIN_M : TypeTrade.USD_M;
  // } else {
  //   const contractType = history.state === TypeTrade.COIN_M ? TypeTrade.COIN_M : TypeTrade.USD_M;
  //   return contractType;
  // }
  return TypeTrade.USD_M;
};

export const getPositionsRequest = () => ({ type: GET_POSITIONS_REQUEST });
export const getPositionsSuccess = (payload: any) => ({ type: GET_POSITIONS_SUCCESS, payload });
export const getPositionsFailure = (error: any) => ({
  type: GET_POSITIONS_FAILURE,
  payload: error,
});

export const updatePositions = (payload: any) => ({ type: UPDATE_POSITIONS, payload });
export const updateAllPositions = (payload: any) => ({ type: UPDATE_ALL_POSITION, payload });

export const getAllPositions = (params: PositionsGetAllParams) => async (dispatch: any) => {
  const positionsService = new PositionsService();
  // dispatch(getPositionsRequest());
  try {
    const response = await positionsService.getListPositions({
      ...params,
    });
    dispatch(updateAllPositions(response.data || []));
  } catch (error) {
    // dispatch(getPositionsFailure(error));
  }
};

export const getPosition = (params: PositionsParams) => async (dispatch: any) => {
  const positionsService = new PositionsService();
  dispatch(getPositionsRequest());
  try {
    const response = await positionsService.getListPositionsAll(params);
    dispatch(
      getPositionsSuccess({
        positions: response.data || [],
        currentPage: params.page,
        totalItems: 1,
        totalPages: 1,
      }),
    );
  } catch (error) {
    dispatch(getPositionsFailure(error));
  }
};

export const updateActivePosition = async (
  store: RootStore,
  updatedPosition: IPositions,
): Promise<void> => {
  let positions = store.getState().positions.positions.concat([]);
  const totalPage = store.getState().positions.totalPage;
  const currentPage = store.getState().positions.currentPage;
  const currentSymbol = store.getState().positions.currentSymbol;
  const index = positions.findIndex(
    (position: IPositions) => position.symbol === updatedPosition.symbol,
  );
  const contractType = getContractType();
  const contractTypeOfPosition = updatedPosition.contractType;
  if (contractTypeOfPosition !== contractType) return;

  if (index >= 0) {
    positions[index] = updatedPosition;
  } else {
    if (currentPage === 1) {
      positions.unshift(updatedPosition);
    }
  }

  positions = positions.filter((position: IPositions) => Number(position.currentQty) !== 0);

  // update all positions
  let positionList = store.getState().positions.allPosition.concat([]);
  const indexPosition = positionList.findIndex(
    (position: IPositions) => position.symbol === updatedPosition.symbol,
  );
  if (indexPosition < 0) {
    positionList.push(updatedPosition);
  } else {
    positionList[indexPosition] = updatedPosition;
  }
  positionList = positionList.filter((position: IPositions) => Number(position.currentQty) !== 0);
  const newTotalItem = positionList.length;
  const newTotalPage = Math.ceil(newTotalItem / RECORDS_PER_PAGE);
  store.dispatch(updateAllPositions(positionList));

  const newTotalItemOfCurrentSymbol = positionList.filter(
    (position: IPositions) => position.symbol === currentSymbol,
  ).length;
  const newTotalPageOfCurrentSymbol = Math.ceil(newTotalItemOfCurrentSymbol / RECORDS_PER_PAGE);

  // update position
  if (currentSymbol) {
    positions = positions.filter((position: IPositions) => position.symbol === currentSymbol);
    if (positions.length >= RECORDS_PER_PAGE) {
      positions = positions.slice(0, RECORDS_PER_PAGE);
      store.dispatch(
        updatePositions({ positions, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }),
      );
    } else if (positions.length < RECORDS_PER_PAGE && positions.length > 0) {
      if (currentPage < totalPage) {
        const payload: any = { page: currentPage, limit: RECORDS_PER_PAGE, contractType };
        payload.symbol = currentSymbol;
        store.dispatch(getPosition(payload));
      } else {
        store.dispatch(
          updatePositions({ positions, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }),
        );
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(
          updatePositions({ positions, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }),
        );
        return;
      }
      const payload: any = {
        page: currentPage > 1 ? currentPage - 1 : 1,
        size: RECORDS_PER_PAGE,
        contractType,
      };
      payload.symbol = currentSymbol;
      store.dispatch(getPosition(payload));
    }
  } else {
    if (positions.length >= RECORDS_PER_PAGE) {
      positions = positions.slice(0, RECORDS_PER_PAGE);
      store.dispatch(updatePositions({ positions, newTotalItem, newTotalPage }));
    } else if (positions.length < RECORDS_PER_PAGE && positions.length > 0) {
      if (currentPage < totalPage) {
        const payload: any = { page: currentPage, size: RECORDS_PER_PAGE, contractType };
        store.dispatch(getPosition(payload));
      } else {
        store.dispatch(updatePositions({ positions, newTotalItem, newTotalPage }));
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(updatePositions({ positions, newTotalItem, newTotalPage }));
        return;
      }
      const payload: any = {
        page: currentPage > 1 ? currentPage - 1 : 1,
        size: RECORDS_PER_PAGE,
        contractType,
      };
      store.dispatch(getPosition(payload));
    }
  }
};
