import { ContractType } from 'src/types/orders';
import { ServiceBase } from './core/service-base';
import {
  AveragePriceParams,
  ClosePositionBody,
  PositionsClose,
  PositionsGetAllParams,
  PositionsInfoParams,
  PositionsParams,
  UpdatePositions,
} from './params-type';

export class PositionsService extends ServiceBase {
  getListPositions = (payload: PositionsParams) => {
    return this.get('/positions', payload);
  };
  getListCoinMarkets = (params: any) => {
    return this.get('/coin-info/markets', params);
  };
  getListPositionsAll = (payload: PositionsGetAllParams) => {
    return this.get('/positions/all', payload);
  };
  getPositionsInfo = (payload: PositionsInfoParams) => {
    return this.get('/positions/info/value', payload);
  };
  getAverageIndexPrice = (payload: AveragePriceParams) => {
    return this.get('/positions/get-average-index-price', payload);
  };
  getPositionsSymbol = (symbol: string) => {
    return this.get(`/positions/${symbol}`);
  };
  getPositionsUpdate = (positionId: string, orderStatus?: string) => {
    return this.get(`/positions/update-position/${positionId}`, {
      orderStatus,
    });
  };
  getPositionsClose = (body: PositionsClose) => {
    return this.post(`/positions/close`, body);
  };
  closeAllPostions = (body: { contractType: ContractType }) => {
    return this.post(`/positions/close-all-positions`, body);
  };
  cancelPositionOrder = (params: {
    takeProfitOrderId?: number;
    positionId: number;
    stopLossOrderId?: number;
  }) => {
    return this.deleteWithParams(`/positions/update-position`, params);
  };
  closePosition = (body: ClosePositionBody) => {
    return this.post(`/positions/close`, body);
  };
  updatePositions = (body: UpdatePositions) => {
    return this.put(`/positions/update-position`, body);
  };
  deletePositions = (body: any) => {
    return this.delete(`/positions/update-positions`, body);
  };
}
