import * as types from '../constants/trade';
import { IMarketTrade } from './ticker';

type States = {
  marketTrades: IMarketTrade[];
  pendingUpdates: IMarketTrade[];
  isReady: boolean;
  lastTradeId: number | null;
  updateAt: number;
};

const initialState: States = {
  marketTrades: [],
  pendingUpdates: [],
  isReady: false,
  lastTradeId: null,
  updateAt: Date.now(),
};
export const RECORD_TRADE_LIMIT = 30;

export const tradeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.ADD_NEW_TRADES:
      if (state.isReady) {
        const updatedTrade = [...action.payload, ...state.marketTrades];
        return {
          ...state,
          marketTrades: updatedTrade.slice(0, RECORD_TRADE_LIMIT),
          lastTradeId: updatedTrade[0]?.id ? Number(updatedTrade[0].id) : state.lastTradeId,
        };
      } else {
        return {
          ...state,
          pendingUpdates: [...action.payload, ...state.pendingUpdates],
          lastTradeId: action.payload[0]?.id ? Number(action.payload[0].id) : state.lastTradeId,
        };
      }
    case types.INIT_TRADES:
      const updatedTrade = [...state.pendingUpdates, ...action.payload];

      return {
        ...state,
        marketTrades: updatedTrade.slice(0, RECORD_TRADE_LIMIT),
        lastTradeId: updatedTrade[0]?.id ? Number(updatedTrade[0].id) : state.lastTradeId,
        isReady: true,
        pendingUpdates: [],
      };

    case types.UPDATE_IS_READY:
      return {
        ...state,
        isReady: action.payload,
      };

    case types.CLEAR_MARKET_TRADES:
      return {
        ...state,
        isReady: false,
        pendingUpdates: [],
        lastTradeId: null,
      };
    case types.CHANGE_UPDATED_AT:
      return {
        ...state,
        updatedAt: action.payload,
      };

    default: {
      return state;
    }
  }
};
