import { Drawer, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PauseIcon } from 'src/assets/icons/IconComponent';
import { ReactComponent as AppLogo } from 'src/assets/icons/header/axor/axor-logo.svg';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import ConnectWalletModal from 'src/components/06.modals/ConnectWalletModal';
import { MENUS_KEY, MENU_ITEMS } from 'src/constants/siderbar';
import { renderLang } from 'src/helpers';
import { useAuth } from 'src/hooks/useAuth';
import useWindowSize from 'src/hooks/useWindowSize';
import { THEME_MODE } from 'src/interfaces/theme';
import { LANGUAGE_CODE } from '../ProfileDetail/MultiLanguageContent';
import AccountContent from './AccountContent';
import MultiLanguageContent from './MultiLanguageContent';
import WalletContent from './WalletContent';
import './mobile-menu-detail-content.scss';

interface IWalletDetail {
  address: string;
  onClickItem: () => void;
  isOpen: boolean;
}

const logoMobileWidth = 90;
const logoDesktopWidth = 107;

const MobileMenuDetail: React.FC<IWalletDetail> = ({ address, onClickItem, isOpen }) => {
  const { t, i18n } = useTranslation();
  const { isAuth } = useAuth();
  const history = useHistory();
  const { isMobile } = useWindowSize();

  const userTheme = useSelector((state: any) => state.theme);
  const account = useSelector((state: any) => state.currentAccount);
  const [language, setLanguage] = useState<any>(
    renderLang(i18n.resolvedLanguage) || LANGUAGE_CODE.ENGLISH,
  );

  const [isOpenMultiLanguage, setIsOpenMultiLanguage] = useState(false);
  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);

  const handleToggleMultiLanguageContent = () => {
    setIsOpenMultiLanguage(!isOpenMultiLanguage);
  };

  const handleToggleWalletContent = () => {
    setIsOpenWallet(!isOpenWallet);
  };

  const handleToggleAccountContent = () => {
    setIsOpenAccount(!isOpenAccount);
  };

  const handleDisconnect = () => {
    setIsOpenAccount(false);
    onClickItem();
  };

  useEffect(() => {
    if (!isOpen) {
      setIsOpenMultiLanguage(false);
      setIsOpenWallet(false);
      setIsOpenAccount(false);
    }
  }, [isOpen]);

  const goToThePage = (page: string) => {
    // if (page.includes('/')) {
    //   return;
    // }
    history.push(`/${page}`);
    onClickItem();
  };
  const items: MenuProps['items'] = [
    {
      icon: MENU_ITEMS.OVER_VIEW.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.OVER_VIEW.label,
      key: MENU_ITEMS.OVER_VIEW.key,
      onClick: () => goToThePage(MENUS_KEY.OVER_VIEW),
    },
    {
      icon: MENU_ITEMS.TRANSACTION_HISTORY.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.TRANSACTION_HISTORY.label,
      key: MENU_ITEMS.TRANSACTION_HISTORY.key,
      children: MENU_ITEMS.TRANSACTION_HISTORY.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENU_ITEMS.PNL.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.PNL.label,
      key: MENU_ITEMS.PNL.key,
      onClick: () => goToThePage(MENUS_KEY.PNL),
    },
    {
      icon: MENU_ITEMS.RANKING.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.RANKING.label,
      key: MENU_ITEMS.RANKING.key,
      children: MENU_ITEMS.RANKING.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
  ];

  if (isOpenMultiLanguage) {
    return (
      <MultiLanguageContent
        language={language}
        onSelectLanguage={setLanguage}
        onClose={handleToggleMultiLanguageContent}
      />
    );
  }

  if (isOpenWallet) {
    return (
      <WalletContent
        address={address}
        onClose={handleToggleWalletContent}
        onClickItem={() => {
          onClickItem();
          setIsOpenWallet(false);
        }}
      />
    );
  }

  if (isOpenAccount) {
    return <AccountContent onClose={handleToggleAccountContent} onDisconnect={handleDisconnect} />;
  }

  return (
    <Drawer
      className={`drawer-menu ${userTheme}`}
      title={
        <div>
          {' '}
          <AppLogo
            width={!isMobile ? logoDesktopWidth : logoMobileWidth}
            color={userTheme === THEME_MODE.DARK ? '#fff' : '#002E26'}
          />
        </div>
      }
      closeIcon={<PauseIcon />}
      placement="left"
      open={isOpen}
      width={300}
      onClose={onClickItem}
    >
      <div className={`mobile-menu-detail ${userTheme}`}>
        <Menu className="menu-portfolio" mode="inline" items={items} />
        {(!isAuth || !account) && (
          <div className="mobile-menu-detail__item-wrapper">
            <div className="mobile-menu-detail__item-wrapper__item">
              <ButtonOutLined
                className="button-connect"
                onClick={() => {
                  setIsOpenConnectWalletModal(true);
                }}
              >
                {t('layout.header.menu.connectWallet')}
              </ButtonOutLined>
            </div>
          </div>
        )}
        <ConnectWalletModal
          open={isOpenConnectWalletModal}
          onCancel={() => setIsOpenConnectWalletModal(false)}
          closeModal={() => setIsOpenConnectWalletModal(false)}
          onLoginSuccess={onClickItem}
        />
      </div>
    </Drawer>
  );
};

export default MobileMenuDetail;
