import { IOrder } from 'src/types/orders';
import {
  ADD_ALL_ORDERS,
  CLEAR_ALL_ORDERS,
  GET_ORDERS,
  GET_ORDER_ERROR,
  UPDATE_ALL_ORDERS,
  UPDATE_LOADING,
  UPDATE_ORDERS,
} from '../constants/orders';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { OrderService } from 'src/services/order-service';
import { RootStore } from '..';
import { OrderStatus } from '../reducers/orders';
import { getContractType } from './positions';
import { fracToast2 } from 'src/components/07.toast';
import { OrderSideFuture, OrderTypeFuture } from 'src/constants/exchange';
import {
  RECORDS_PER_PAGE,
  TypeTrade,
} from 'src/pages/exchange/components/03.order-history/components/PostitionTab/consts';
import { ListOrderParams } from 'src/services/params-type';
import i18n from 'src/i18n/i18n';
import i18next from 'i18next';

export const getAllOpenOrder = (payload: IOrder[]) => {
  return {
    type: ADD_ALL_ORDERS,
    payload,
  };
};

export const clearAllOpenOrder = () => {
  return {
    type: CLEAR_ALL_ORDERS,
  };
};

export const updateAllOpenOrder = (payload: IOrder[]) => {
  return {
    type: UPDATE_ALL_ORDERS,
    payload,
  };
};

export const getOpenOrder = (payload: any) => {
  return {
    type: GET_ORDERS,
    payload,
  };
};

export const updateOpenOrder = (payload: any) => {
  return {
    type: UPDATE_ORDERS,
    payload,
  };
};

export const updateLoading = (payload: boolean) => {
  return {
    type: UPDATE_LOADING,
    payload,
  };
};

export const getOpenOrderFailed = (payload: any) => {
  return {
    type: GET_ORDER_ERROR,
    payload,
  };
};

export const handleGetOpenOrder = ({
  params,
  payload,
}: {
  params: {
    page: number;
    size: number;
  };
  payload: {
    symbol?: string;
    contractType: TypeTrade;
  };
}) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(updateLoading(true));
      const controller = new OrderService();
      const body: ListOrderParams = {
        page: params.page,
        limit: params.size,
        contractType: payload.contractType,
        symbol: payload.symbol,
        direction: 'DESC',
        orderBy: 'createdAt',
      };
      const { data, metadata: pageData } = await controller.getListOrders(body);

      dispatch(
        getOpenOrder({
          orders: data,
          currentPage: params.page,
          totalItem: pageData.total,
          totalPage: pageData.total,
        }),
      );
    } catch (error: any) {
      getOpenOrderFailed({ error: error?.message });
    }
    dispatch(updateLoading(false));
  };
};

export const handleGetAllOpenOrder = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const orderBookServices = new OrderService();
    try {
      const res = await orderBookServices.getListOrders({
        contractType: TypeTrade.USD_M,
        getAll: true,
      });

      dispatch(getAllOpenOrder(res.data));
    } catch (err) {
      dispatch(clearAllOpenOrder());
    }
  };
};

export const updateActiveOrders = async (store: RootStore, newOrders: IOrder[]): Promise<void> => {
  let orders: IOrder[] = store.getState().order.orders.concat([]);
  const totalPage = store.getState().order.totalPage;
  const currentPage = store.getState().order.currentPage;
  const currentSymbol = store.getState().instrument?.currentInstrument?.symbol;
  const removingStatuses = [
    OrderStatus.CANCELED.toString(),
    OrderStatus.FILLED.toString(),
    OrderStatus.PENDING.toString(),
    OrderStatus.UNTRIGGERED.toString(),
  ];
  const addingStatuses = [OrderStatus.ACTIVE.toString(), OrderStatus.UNTRIGGERED.toString()];
  const contractType = getContractType();
  const filterOrders = newOrders.filter((order) => order.contractType === contractType);
  const resourcesOrder = i18next.getResource(i18n.language, 'orders', 'noti');

  let isTpSlTriggered = false;
  const findRootOrder = filterOrders.find(
    (item) => !item.parentOrderId && item.isTpSlTriggered === true,
  );
  if (findRootOrder) {
    const isTakeProfitOrderTriggered = filterOrders.some(
      (item) => Number(item.id) === Number(findRootOrder?.takeProfitOrderId),
    );
    const isStopLossOrderTriggered = filterOrders.some(
      (item) => Number(item.id) === Number(findRootOrder?.stopLossOrderId),
    );
    isTpSlTriggered = isTakeProfitOrderTriggered || isStopLossOrderTriggered;
  }

  let removingOrders = filterOrders.filter((order) =>
    removingStatuses.includes(order.status || ''),
  );
  let addingOrders = filterOrders.filter((order) => addingStatuses.includes(order.status || ''));

  removingOrders = removingOrders.filter(
    (order) => order.status !== OrderStatus.UNTRIGGERED.toString() || order.isHidden === true,
  );

  addingOrders = addingOrders.filter(
    (order) => order.status !== OrderStatus.UNTRIGGERED.toString() || order.isHidden === false,
  );

  // const resources = i18next.getResource(i18n.language, 'common', 'order');

  for (const order of removingOrders) {
    orders = orders.filter((item) => Number(item.id) !== order.id);
    if (order.status === OrderStatus.CANCELED.toString()) {
      if (order.isTriggered === true) {
        // Take profit triggered
        if (order.tpSLType === OrderTypeFuture.takeProfitMarket) {
          fracToast2.success('Take profit order has been triggered');
        }
        // Stop loss triggered
        if (order.tpSLType === OrderTypeFuture.stopMarket && order.isTpSlOrder) {
          fracToast2.success('Stop loss order has been triggered');
        }
        // Stop market triggered, trailing stop triggered
        if (
          (order.tpSLType === OrderTypeFuture.stopMarket &&
            !order.isTpSlOrder &&
            isTpSlTriggered === false) ||
          order.tpSLType === OrderTypeFuture.trailingStop
        ) {
          fracToast2.success('Stop order has been triggered');
        }
      }
    }

    if (order.status === OrderStatus.FILLED.toString()) {
      if (order.isTriggered === true) {
        // Take profit triggered
        if (order.tpSLType === OrderTypeFuture.takeProfitMarket) {
          fracToast2.success('Take profit order has been triggered');
        }
        // Stop loss triggered
        if (order.tpSLType === OrderTypeFuture.stopMarket && order.isTpSlOrder) {
          fracToast2.success('Stop loss order has been triggered');
        }
        // Stop market triggered, trailing stop triggered
        if (
          (order.tpSLType === OrderTypeFuture.stopMarket &&
            !order.isTpSlOrder &&
            isTpSlTriggered === false) ||
          (order.tpSLType === OrderTypeFuture.stopLimit &&
            !order.isTpSlOrder &&
            isTpSlTriggered === false) ||
          order.tpSLType === OrderTypeFuture.trailingStop
        ) {
          fracToast2.success('Stop order has been triggered');
        }
      }

      if (order.parentOrderId || (!order.parentOrderId && isTpSlTriggered === false)) {
        fracToast2.success(
          order.side === OrderSideFuture.buy
            ? 'Buy order filled successfully'
            : 'Sell order filled successfully',
        );
      }
    }
  }

  for (const order of addingOrders) {
    const index = orders.findIndex((item) => Number(item.id) === order.id);
    if (index >= 0) {
      orders[index] = order;
    } else {
      if (currentPage === 1) {
        orders.unshift(order);
      }
    }

    if (order.status === OrderStatus.ACTIVE.toString()) {
      if (order.isTriggered) {
        // Stop limit triggered
        if (
          isTpSlTriggered === false &&
          order.isShowToast === true &&
          order.tpSLType === OrderTypeFuture.stopLimit &&
          Number(order.remaining) === Number(order.quantity)
        ) {
          fracToast2.success('Stop order has been triggered');
        }
      }

      if (
        isTpSlTriggered === false &&
        order.isShowToast === true &&
        order.type === OrderTypeFuture.limit &&
        Number(order.remaining) > 0 &&
        Number(order.remaining) < Number(order.quantity)
      ) {
        fracToast2.success(
          order.side === OrderSideFuture.buy
            ? resourcesOrder.buy_order_partially_fill
            : resourcesOrder.sell_order_partially_fill,
        );
      }
    }
  }

  // update all orders
  let orderList: IOrder[] = store.getState().order.allOrders.concat([]);
  for (const order of removingOrders) {
    orderList = orderList.filter((item) => Number(item.id) !== order.id);
  }
  for (const order of addingOrders) {
    const index = orderList.findIndex((item) => Number(item.id) === order.id);
    if (index >= 0) {
      orderList[index] = order;
    } else {
      orderList.unshift(order);
    }
  }
  const newTotalItem = orderList.length;
  const newTotalPage = Math.ceil(newTotalItem / RECORDS_PER_PAGE);
  store.dispatch(updateAllOpenOrder(orderList));

  const newTotalItemOfCurrentSymbol = orderList.filter(
    (order) => order.symbol === currentSymbol,
  ).length;
  const newTotalPageOfCurrentSymbol = Math.ceil(newTotalItemOfCurrentSymbol / RECORDS_PER_PAGE);

  // update order
  if (currentSymbol) {
    orders = orders.filter((order) => order.symbol === currentSymbol);
    if (orders.length >= RECORDS_PER_PAGE) {
      orders = orders.slice(0, RECORDS_PER_PAGE);
      store.dispatch(
        updateOpenOrder({ orders, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }),
      );
    } else if (orders.length < RECORDS_PER_PAGE && orders.length > 0) {
      if (currentPage < totalPage) {
        const params = { page: currentPage, size: RECORDS_PER_PAGE };
        const payload = { symbol: currentSymbol, contractType };
        store.dispatch(handleGetOpenOrder({ params, payload }));
      } else {
        store.dispatch(
          updateOpenOrder({ orders, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }),
        );
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(
          updateOpenOrder({ orders, newTotalItemOfCurrentSymbol, newTotalPageOfCurrentSymbol }),
        );
        return;
      }
      const params: any = { page: currentPage > 1 ? currentPage - 1 : 1, size: RECORDS_PER_PAGE };
      const payload = { symbol: currentSymbol, contractType };
      store.dispatch(handleGetOpenOrder({ params, payload }));
    }
  } else {
    if (orders.length >= RECORDS_PER_PAGE) {
      orders = orders.slice(0, RECORDS_PER_PAGE);
      store.dispatch(updateOpenOrder({ orders, newTotalItem, newTotalPage }));
    } else if (orders.length < RECORDS_PER_PAGE && orders.length > 0) {
      if (currentPage < totalPage) {
        const params = { page: currentPage, size: RECORDS_PER_PAGE };
        const payload = { symbol: currentSymbol, contractType };
        store.dispatch(handleGetOpenOrder({ params, payload }));
      } else {
        store.dispatch(updateOpenOrder({ orders, newTotalItem, newTotalPage }));
      }
    } else {
      if (currentPage === 1 && currentPage >= totalPage) {
        store.dispatch(updateOpenOrder({ orders, newTotalItem, newTotalPage }));
        return;
      }
      const params: any = { page: currentPage > 1 ? currentPage - 1 : 1, size: RECORDS_PER_PAGE };
      const payload = { contractType };
      store.dispatch(handleGetOpenOrder({ params, payload }));
    }
  }
};
