import {
  SET_ACCESS_TOKEN,
  SET_CURRENT_CONNECTOR
} from '../constants/user';

export const setAccessTokenRedux = (payload: string) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload,
  };
};
export const setCurrentConnector = (payload: string) => {
  return {
    type: SET_CURRENT_CONNECTOR,
    payload,
  };
};