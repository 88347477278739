import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import { useDispatch } from 'react-redux';
import { fracToast2 } from 'src/components/07.toast';
import { setStorageJwtRefreshToken, setStorageJwtToken, userAddress } from 'src/helpers/storage';
import { AuthServices } from 'src/services/auth-service';
import { BaseSocket } from 'src/socket/BaseSocket';
import { setCurrentAccount } from 'src/store/actions/account';
import { setAccessTokenRedux } from 'src/store/actions/user';
import {
  setBalanceAll,
  setBalanceAvailable,
  setBalanceInOrder,
} from 'src/store/actions/wallet-analytic';
import { ConnectorKey } from 'src/web3/connectors';
import { REACT_APP_MESSAGES_SIGN } from 'src/web3/constants/envs';
import { CONNECTOR_KEY } from 'src/web3/constants/storages';
import { signMessage } from 'src/web3/helpers';
import { useConnectWallet } from 'src/web3/hooks';

export const apiEndPoint = process.env.REACT_APP_API_URL;

export const useLogin = () => {
  const dispatch = useDispatch();
  const { disconnectWallet } = useConnectWallet();
  const authService = new AuthServices();

  const getSignature = async (
    provider: Web3Provider,
  ): Promise<{ signature: string; message: string }> => {
    const message = `${REACT_APP_MESSAGES_SIGN}`;
    const signer = provider?.getSigner();
    const signature = await signMessage(signer, message);

    return {
      message,
      signature,
    };
  };

  const retrySocket = async (accessToken?: string) => {
    // BaseSocket.getInstance().disconnectSocket();
    BaseSocket.getInstance().reconnect(accessToken);
  };

  const resetStore = (accessToken: string, accountSelected: string) => {
    dispatch(setAccessTokenRedux(accessToken));
    dispatch(setCurrentAccount(accountSelected as string));
    dispatch(setBalanceAvailable([]));
    dispatch(setBalanceInOrder([]));
    dispatch(setBalanceAll([]));
  };

  const userLogin = async (payload: any) => {
    try {
      const provider = new Web3Provider(
        (await payload?.connector.getProvider()) as ExternalProvider,
      );
      const accountSelected = payload.address;
      const { signature } = await getSignature(provider);

      const res = await authService.login({ walletAddress: accountSelected, signature });
      const { data } = res;

      const accessToken = data?.accessToken;
      const refreshToken = data?.refreshToken;
      resetStore(accessToken, accountSelected);
      setStorageJwtToken(accessToken);
      setStorageJwtRefreshToken(refreshToken);
      localStorage.setItem(userAddress, accountSelected);
      localStorage.setItem(CONNECTOR_KEY, ConnectorKey.metaMask);
      retrySocket(accessToken);
      fracToast2.success('Connected successfully', 'Connected successfully');
    } catch (error: any) {
      localStorage.removeItem(userAddress);
      disconnectWallet(false);
      resetStore('', '');
      throw error;
    }
  };

  return { userLogin, retrySocket };
};
