import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const useOpenModal = () => {
  const isModalOpen: boolean = useSelector((state: RootState) => state.general.isModalOpen);

  const toggleOverflow = (state: boolean) => {
    document.body.classList.toggle('hidden', state);
  };

  useEffect(() => toggleOverflow(isModalOpen), [isModalOpen]);
};

export default useOpenModal;
