import { SET_FEE } from '../constants/fee';

const initialState = {
  limitFee: 0,
  marketFee: 0,
};

export const feeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FEE:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
