// tickerReducers.ts
import { ITrade } from 'src/interfaces/trade';
import * as types from '../constants/ticker';

interface ITicker {
  symbol: string;
  priceChange: string;
  priceChangePercent: string;
  lastPrice: string;
  lastPriceChange: string;
  highPrice: string;
  lowPrice: string;
  volume: string;
  quoteVolume: string;
  indexPrice: string;
  oraclePrice: string;
  fundingRate: string;
  nextFunding: number;
  trades: ITrade[];
  updateAt: number;
  lastUpdateAt: number;
}
export interface IMarketTrade {
  id?: string;
  instrumentSymbol: string;
  price: string;
  quantity: string;
  buyerIsTaker: boolean;
  createdAt: string;
}

const initialState: {
  loading: boolean;
  error: boolean;
  tickers: ITicker[];
} = {
  loading: false,
  error: false,
  tickers: [],
};

const tickerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_TICKER_24H_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_TICKER_24H_SUCCESS:
      return {
        ...state,
        loading: false,
        tickers: action.payload,
      };
    case types.GET_TICKER_24H_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.UPDATE_TICKERS:
      const ticketsNotNull = action.payload.filter((item: ITicker) => item.lastPrice);
      if (ticketsNotNull) {
        const updateTicker =
          state.tickers.length > 0 &&
          state.tickers.map(
            (item) =>
              ticketsNotNull.find((ticker: ITicker) => ticker?.symbol === item?.symbol) || item,
          );
        return {
          ...state,
          tickers: updateTicker || [],
        };
      }
      return {
        ...state,
        tickers: action.payload || [],
      };
    default:
      return state;
  }
};

export default tickerReducer;
