import { walletAnalytic } from '../constants/wallet-analytic';

const initialState = {
  balanceAll: [],
  balanceAvailable: [],
  balanceInOrder: [],
  exChangeRate: [],
};

export const walletAnalyticReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case walletAnalytic.SET_BALANCE_ALL:
      return {
        ...state,
        balanceAll: action.payload,
      };
    case walletAnalytic.SET_EXCHANGE_RATE:
      return {
        ...state,
        exChangeRate: action.payload,
      };
    case walletAnalytic.SET_BALANCE_IN_ORDER:
      return {
        ...state,
        balanceInOrder: action.payload,
      };
    case walletAnalytic.SET_BALANCE_AVAILABLE:
      return {
        ...state,
        balanceAvailable: action.payload,
      };

    default:
      return state;
  }
};
