import { Button, ButtonProps } from 'antd';
import React from 'react';
import './styles/button-outlined.scss';

interface IButtonOutLinedProps extends ButtonProps {
  noBorder?: boolean;
}

const ButtonOutLined: React.FC<IButtonOutLinedProps> = (props: IButtonOutLinedProps) => {
  const { children, className, size, noBorder, ...restprops } = props;
  return (
    <Button
      className={`outlined-default outlined-default--${size || 'small'} ${className || ''} ${
        noBorder ? 'outlined-default--noBorder' : ''
      }`}
      {...restprops}
    >
      {children}
    </Button>
  );
};

export default ButtonOutLined;
