import { ServiceBase } from './core/service-base';
import { GetMarginModeType, UpdateMarginModeType } from './params-type';

export class MarginModeService extends ServiceBase {
  getMarginMode = (params: GetMarginModeType) => {
    return this.get(`/marginMode`, params);
  };

  updateMarginMode = (params: UpdateMarginModeType) => {
    return this.post(`/marginMode`, params);
  };
}
