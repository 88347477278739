import { ServiceBase } from './core/service-base';
import { IPagination, MarketTradeResponse, TradeHistoryParams, TradeParams } from './params-type';

export class TradeService extends ServiceBase {
  getTradesBars = (payload: TradeParams, symbol: string) => {
    const encodeSymbol = encodeURIComponent(symbol);
    return this.get(`/candle/${encodeSymbol}/candles`, payload);
  };

  getListTradeHistory = (payload: TradeHistoryParams) => {
    return this.get('/trade/fill', payload);
  };

  getListTradeByPairSymbol = (
    pairSymbol: string,
    params?: IPagination,
  ): Promise<{
    data: MarketTradeResponse[];
    metadata: any;
  }> => {
    const pairSymbolEncode = encodeURIComponent(pairSymbol);
    return this.get('/trade/' + pairSymbolEncode, params);
  };
}
