import {
  MASTER_DATA_FUTURE_FULFILLED,
  MASTER_DATA_FUTURE_PENDING,
  MASTER_DATA_FUTURE_REJECTED,
} from '../constants/masterdataFuture';

interface Ileverage {
  maxLeverage: number;
  max: string;
  min: string;
  maintenanceAmount: string;
  maintenanceMarginRate: number;
  tier: number;
  instrumentId: any;
  symbol: string;
}
export interface ITradingRuleCache {
  maxLeverage: number;
  symbol: string;
  minOrderAmount: string;
  minPrice: string;
  maxOrderAmount: string;
  maxPrice: string;
  maxFiguresForPrice: string;
  maxFiguresForSize: string;
  id: number;
  instrumentId: number;
  minTradeAmount: string;
  limitOrderPrice: string;
  floorRatio: string;
  maxMarketOrder: string;
  limitOrderAmount: string;
  numberOpenOrders: string;
  priceProtectionThreshold: string;
  liqClearanceFee: string;
  minNotional: string;
  marketOrderPrice: string;
  isReduceOnly: boolean;
  positionsNotional: string;
  ratioOfPostion: string;
  liqMarkPrice: string;
  createdAt: string;
  updatedAt: string;
}

interface ICoinInfo {
  id: string;
  fullName: string;
  baseId: string;
  symbol: string;
  rank: number;
  marketCap: string;
  cirSupply: string;
  maxSupply: string;
  totalSupply: string;
  issueDate: string;
  issuePrice: string;
  explorer: string;
  coin_image: string;
  createdAt: string;
  updatedAt: string;
}
const initialState: {
  loading: boolean;
  error: boolean;
  leverageMarginCache: Ileverage[];
  tradingRulesCache: ITradingRuleCache[];
  coinInfo: ICoinInfo[];
} = {
  loading: false,
  error: false,
  leverageMarginCache: [
    {
      maxLeverage: 0,
      max: '',
      min: '',
      maintenanceAmount: '',
      maintenanceMarginRate: 0,
      tier: 0,
      symbol: '',
      instrumentId: [],
    },
  ],
  tradingRulesCache: [
    {
      maxLeverage: 0,
      symbol: '',
      minOrderAmount: '0',
      minPrice: '0',
      maxOrderAmount: '0',
      maxPrice: '0',
      maxFiguresForPrice: '0',
      maxFiguresForSize: '0',
      createdAt: '',
      floorRatio: '',
      id: 0,
      instrumentId: 0,
      isReduceOnly: false,
      limitOrderAmount: '',
      limitOrderPrice: '',
      liqClearanceFee: '',
      liqMarkPrice: '',
      marketOrderPrice: '',
      maxMarketOrder: '',
      minNotional: '',
      minTradeAmount: '',
      numberOpenOrders: '',
      positionsNotional: '',
      priceProtectionThreshold: '',
      ratioOfPostion: '',
      updatedAt: '',
    },
  ],
  coinInfo: [
    {
      id: '',
      fullName: '',
      baseId: '',
      symbol: '',
      rank: 1,
      marketCap: '',
      cirSupply: '',
      maxSupply: '',
      totalSupply: '',
      issueDate: '',
      issuePrice: '',
      explorer: '',
      coin_image: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
};

export const masterdataFutureReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case MASTER_DATA_FUTURE_PENDING:
      return {
        ...state,
        loading: true,
      };

    case MASTER_DATA_FUTURE_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.error,
        // Handle local storage here similar to your logic in the rejected case
      };

    case MASTER_DATA_FUTURE_FULFILLED:
      return {
        ...state,
        loading: false,
        leverageMarginCache: action.payload.leverageMargin || [],
        tradingRulesCache: action.payload.tradingRules || [],
        coinInfo: action.payload.coinInfo || [],
      };

    default:
      return state;
  }
};
