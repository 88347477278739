import type { MenuProps } from 'antd';
import { Layout, Menu, Skeleton } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { MENUS_KEY, MENU_ITEMS } from 'src/constants/siderbar';
import { useAuth } from 'src/hooks/useAuth';
import useWindowSize from 'src/hooks/useWindowSize';
import './styles.scss';
import FooterPortfolio from './components/footer';
import { useAccount } from 'wagmi';
const { Header, Content, Sider } = Layout;

export enum PnlRankingSort {
  ABSOLUTE_PNL = 'absolutePnl',
  RELATIVE_PNL = 'relativePnl',
}

interface ILayoutProps {
  children?: React.ReactNode;
}

const Portfolio: React.FC<ILayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const { isAuth, wrongChain, isAuthChecking } = useAuth();
  const { address: account } = useAccount();
  const { isDesktop, isMobileAndTablet } = useWindowSize();

  const history = useHistory();
  const location = useLocation();
  const userTheme = useSelector((state: any) => state.theme);
  const [loading, setLoading] = useState(true);
  const [menuSelect, setMenuSelect] = useState<string>(MENU_ITEMS.OVER_VIEW.key);
  const [titleHeader, setTitleHeader] = useState('');

  const isLogined = useMemo(() => {
    return isAuth && !!account && !wrongChain && !isAuthChecking;
  }, [isAuth, account, wrongChain, isAuthChecking]);

  const segments = location.pathname.split('/');

  const goToThePage = (page: string) => {
    // if (page.includes('/')) {
    //   return;
    // }
    history.push(`/${page}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const items: MenuProps['items'] = [
    {
      icon: MENU_ITEMS.OVER_VIEW.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.OVER_VIEW.label,
      key: MENU_ITEMS.OVER_VIEW.key,
      onClick: () => goToThePage(MENUS_KEY.OVER_VIEW),
    },
    {
      icon: MENU_ITEMS.TRANSACTION_HISTORY.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.TRANSACTION_HISTORY.label,
      key: MENU_ITEMS.TRANSACTION_HISTORY.key,
      children: MENU_ITEMS.TRANSACTION_HISTORY.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENU_ITEMS.PNL.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.PNL.label,
      key: MENU_ITEMS.PNL.key,
      onClick: () => goToThePage(MENUS_KEY.PNL),
    },
    {
      icon: MENU_ITEMS.RANKING.icon(userTheme === 'Dark' ? { color: '#6D7387' } : {}),
      label: MENU_ITEMS.RANKING.label,
      key: MENU_ITEMS.RANKING.key,
      children: MENU_ITEMS.RANKING.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
  ];
  useEffect(() => {
    const title = segments[segments.length - 1].replace(/-/g, ' ');
    if (title === 'overview') {
      setMenuSelect(MENU_ITEMS.OVER_VIEW.key);
      setTitleHeader(t('portfolios:portfolios.portfolio_overview'));
    }
    if (title === 'open order') {
      setMenuSelect(MENU_ITEMS.TRANSACTION_HISTORY.children[0].key);
      setTitleHeader(t('portfolios:portfolios.open_order'));
    }
    if (title === 'order history') {
      setMenuSelect(MENU_ITEMS.TRANSACTION_HISTORY.children[1].key);
      setTitleHeader(t('portfolios:portfolios.order_history'));
    }
    if (title === 'trade history') {
      setMenuSelect(MENU_ITEMS.TRANSACTION_HISTORY.children[2].key);
      setTitleHeader(t('portfolios:portfolios.trade_history'));
    }
    if (title === 'transaction history') {
      setMenuSelect(MENU_ITEMS.TRANSACTION_HISTORY.children[3].key);
      setTitleHeader(t('portfolios:portfolios.transaction_history'));
    }
    if (title === 'pnl') {
      setMenuSelect(MENU_ITEMS.PNL.key);
      setTitleHeader(t('portfolios:portfolios.portfolio_overview'));
    }
    if (title === 'absolute') {
      setMenuSelect(MENU_ITEMS.RANKING.children[0].key);
      setTitleHeader(t('portfolios:portfolios.ranking_by_profit_loss', { text: '' }));
    }
    if (title === 'relative') {
      setMenuSelect(MENU_ITEMS.RANKING.children[0].key);
      setTitleHeader(t('portfolios:portfolios.ranking_by_profit_loss', { text: '(Percent)' }));
    }
    if (title === 'daily') {
      setMenuSelect(MENU_ITEMS.RANKING.children[0].key);
      setTitleHeader(t('portfolios:portfolios.daily'));
    }
    if (title === 'epoch') {
      setMenuSelect(MENU_ITEMS.RANKING.children[0].key);
      setTitleHeader(t('portfolios:portfolios.epoch'));
    }
    if (title === 'badges') {
      setMenuSelect(MENU_ITEMS.RANKING.children[0].key);
      setTitleHeader(t('portfolios:portfolios.badges'));
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments]);
  return (
    <>
      {loading || isAuthChecking ? (
        <Layout className={`portfolio ${userTheme}`}>
          {isDesktop && (
            <Sider width={300} className="sider-portfolio">
              <Skeleton active={true} />
              <Skeleton active={true} />
              <Skeleton active={true} />
            </Sider>
          )}

          <Layout>
            <Header className="header-portfolio">
              <Skeleton.Input active={true} />
            </Header>
            <Content className={`content-portfolio`}>
              <Skeleton active={true} />
              <Skeleton active={true} />
              <Skeleton active={true} />
              <Skeleton active={true} />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Layout className={`portfolio ${userTheme}`}>
          {(isDesktop || !isMobileAndTablet) && (
            <Sider width={300} className="sider-portfolio">
              <Menu
                mode="inline"
                defaultSelectedKeys={[menuSelect]}
                defaultOpenKeys={[menuSelect]}
                className="menu-portfolio"
                items={items}
              />
            </Sider>
          )}

          <Layout>
            <Header className="header-portfolio">
              <h2 className="header-portfolio__title">{titleHeader}</h2>
            </Header>
            <Content className={`content-portfolio`}>
              {isLogined ? children : <>{history.push(PATHS.exchange())}</>}
            </Content>
            <FooterPortfolio />
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default Portfolio;
