const accessToken = 'dex_access_token';
const refreshToken = 'dex_refresh_token';

const accessTokenIAO = 'user_access_token';
const chartKey = 'chart';
export const themeIAO = 'theme';
export const userThemeIAO = 'user_theme';
export const userLanguageIAO = 'user_language';
export const userAddress = 'user_address';
export const logKey = 'log';

export function hasStorageJwtToken() {
  return !!localStorage.getItem(accessToken);
}
export function hasLoggedInIaoDex() {
  return (
    !!localStorage.getItem(accessToken) &&
    !!localStorage.getItem(accessTokenIAO) &&
    !!localStorage.getItem(userAddress)
  );
}

export function removeStorageJwtToken() {
  window.localStorage.removeItem(accessToken);
  window.localStorage.removeItem(refreshToken);
  window.localStorage.removeItem(userAddress);
}

export function setStorageJwtToken(token: string) {
  localStorage.setItem(accessToken, token);
}

export function setStorageJwtRefreshToken(token: string) {
  localStorage.setItem(refreshToken, token);
}

export function setStorageLogKey() {
  localStorage.setItem(logKey, 'true');
}
export function getStorageLogKey() {
  return localStorage.getItem(logKey);
}
export function removeStorageLogKey() {
  localStorage.removeItem(logKey);
}

export function getStorageJwtRefreshToken() {
  return localStorage.getItem(refreshToken);
}

export function getStorageJwtToken() {
  return localStorage.getItem(accessToken);
}

export function getStorageUserAddress() {
  return localStorage.getItem(userAddress);
}

export function setStorageTheme(theme: string) {
  localStorage.setItem(themeIAO, theme);
  localStorage.setItem(userThemeIAO, theme);
}

export function setStorageLanguage(language: string) {
  localStorage.setItem(userLanguageIAO, language);
}

export function getStorageUserTheme() {
  return localStorage.getItem(userThemeIAO);
}

export function getStorageUserLanguage() {
  return localStorage.getItem(userLanguageIAO);
}

export function setChartData(id: string, data: any) {
  return localStorage.setItem(chartKey + '-' + id, JSON.stringify(data));
}

export function getChartData(id: string) {
  const data = localStorage.getItem(chartKey + '-' + id);
  return data ? JSON.parse(data) : null;
}
