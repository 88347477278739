import { Instrument } from 'src/interfaces/pair';
import { FavoriteService } from 'src/services/favorite-service';
import { InstrumentsService } from 'src/services/instruments-service';
import {
  GET_FAV_INSTRUMENT,
  GET_INSTRUMENT,
  SET_CURRENT_INSTRUMENT,
  UPDATE_FAV_INSTRUMENT,
} from '../constants/instrument';

export const setCurrentInstrument = (instrument: Instrument) => {
  return {
    type: SET_CURRENT_INSTRUMENT,
    payload: instrument,
  };
};
export const getInstrument = () => async (dispatch: any) => {
  dispatch({ type: `${GET_INSTRUMENT}_pending` });
  try {
    const instrumentsService = new InstrumentsService();
    const { data: res } = await instrumentsService.getInstruments();
    dispatch({
      type: `${GET_INSTRUMENT}_fulfilled`,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: `${GET_INSTRUMENT}_rejected` });
  }
};

export const getFavInstrument = () => async (dispatch: any) => {
  try {
    const favoriteService = new FavoriteService();
    const { data: res } = await favoriteService.getFavorite();

    dispatch({
      type: GET_FAV_INSTRUMENT,
      payload: res,
    });
  } catch (error) {}
};

export const updateFavInstrument = () => async (payload: any) => {
  return {
    type: UPDATE_FAV_INSTRUMENT,
    payload,
  };
};
