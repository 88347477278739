import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { useAuth } from './useAuth';
import { getMasterDataFuture } from 'src/store/actions/masterdataFuture';
import { getBalanceAssets, getPreference } from 'src/store/actions/account';
import { getTicker24h } from 'src/store/actions/ticker';
import { RootState } from 'src/store';
import { fetchOrderBook } from 'src/store/actions/order-book';
import { handleGetAllOpenOrder } from 'src/store/actions/orders';
import { BaseSocket } from 'src/socket/BaseSocket';
import { getStorageJwtToken } from 'src/helpers/storage';

const WindowActiveListener = () => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { isAuth, wrongChain, isAuthChecking } = useAuth();
  const currentInstrument = useSelector((state: RootState) => state.instrument.currentInstrument);
  const userLoginSuccess = useMemo(() => {
    return isAuth && !isEmpty(address) && !wrongChain && !isAuthChecking;
  }, [isAuth, address, wrongChain, isAuthChecking]);

  const handleReloadOrderBook = () => {
    dispatch(fetchOrderBook(currentInstrument?.symbol));
  };

  const handleReloadAllData = async () => {
    BaseSocket.getInstance().disconnectSocket();
    dispatch(getTicker24h());
    dispatch(getMasterDataFuture());
    handleReloadOrderBook();
    if (userLoginSuccess) {
      dispatch(getPreference());
      dispatch(handleGetAllOpenOrder());
      dispatch(getBalanceAssets());
    }

    setTimeout(() => {
      BaseSocket.getInstance().reconnect(getStorageJwtToken() || undefined);
      BaseSocket.getInstance().listenBalance();
    }, 1000);
  };

  const handle = () => {
    if (!document.hidden) {
      handleReloadAllData();
    }
  };

  useEffect(() => {
    window.document.addEventListener('visibilitychange', handle);
    return window.document.removeEventListener('visibilitychange', handle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginSuccess, currentInstrument]);
};

export default WindowActiveListener;
