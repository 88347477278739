import Icon from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ArrowLeftLanguageIconComponent, CheckIconComponent } from 'src/assets/icons/IconComponent';
import i18n from 'src/i18n/i18n';
import './profile-detail-content.scss';

interface Props {
  language: string;
  onSelectLanguage: (value: string) => void;
  onClose: () => void;
}

export const LANGUAGE_CODE: any = {
  ENGLISH: 'en', // English
  CHINESE: 'zh', // Chinese
  JAPANESE: 'ja', // Japanese
  KOREAN: 'ko', // Korean
  ARABIC: 'ar', // Arabic
  CZECH: 'cs', // Czech
  DANISH: 'da_DK', // Danish
  DUTCH: 'nl_NL', // Dutch
  ESTONIAN: 'et_EE', // Estonian
  FRENCH: 'fr', // French
  GERMAN: 'de', // German
  GREEK: 'el', // Greek
  HEBREW: 'he_IL', // Hebrew
  INDONESIAN: 'id_ID', // Indonesian
  ITALIAN: 'it', // Italian
  PERSIAN: 'fa', // Persian (Farsi)
  PORTUGUESE: 'pt', // Portuguese
  ROMANIAN: 'ro', // Romanian
  RUSSIAN: 'ru', // Russian
  SLOVAK: 'sk_SK', // Slovak
  SPANISH: 'es', // Spanish
  SWEDISH: 'sv', // Swedish
  THAI: 'th', // Thai
  TURKISH: 'tr', // Turkish
  VIETNAMESE: 'vi', // Vietnamese
};

export const languages = {
  [LANGUAGE_CODE.ENGLISH]: {
    className: 'pb-0 option-text-bold',
    label: 'English',
  },
  [LANGUAGE_CODE.CHINESE]: {
    className: 'pb-0 option-text-bold',
    label: '中文（简体）',
  },
  [LANGUAGE_CODE.JAPANESE]: {
    className: 'pb-0 option-text-bold',
    label: '日本語',
  },
  [LANGUAGE_CODE.VIETNAMESE]: {
    className: 'pb-0 option-text-bold',
    label: 'Tiếng Việt',
  },
};

const MultiLanguageContent: React.FC<Props> = ({ language, onSelectLanguage, onClose }: Props) => {
  const { t } = useTranslation();
  const userTheme = useSelector((state: any) => state.theme);

  const handleChangeLanguage = (item: any) => {
    if (onSelectLanguage) {
      onSelectLanguage(item.langCode);
    }
    i18n.changeLanguage(item.langCode);
  };

  const langArr = useMemo(() => {
    return Object.keys(languages)
      ?.map((item: any) => item)
      ?.map((lang: any) => ({ langCode: lang, value: lang, ...languages[lang] }));
  }, []);

  return (
    <div className={`language-content ${userTheme}`}>
      <div
        className="profile-detail__item-wrapper"
        // onClick={onClose}
      >
        <div className="profile-detail__item justify-flex-start cursor-pointer">
          <Icon
            className="item-icon profile-detail-icon"
            component={ArrowLeftLanguageIconComponent}
          />
          <span>{t('layout.header.profileDetail.onHoverLanguage')}</span>
        </div>
      </div>

      <div className="line"></div>

      {langArr.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`profile-detail__item-wrapper ${item.className}`}
            onClick={() => {
              handleChangeLanguage(item);
            }}
          >
            <div className="profile-detail__item justify-flex-start cursor-pointer">
              <Icon
                className={`item-icon profile-detail-icon check-icon ${
                  item.langCode === language ? '' : 'visibility-hidden'
                }`}
                component={CheckIconComponent}
              />
              <span>{item.label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultiLanguageContent;
