import { Layout } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import { FracSelectWithLanguage } from 'src/components/11.select';
import { getStorageJwtToken, setStorageLanguage, setStorageTheme } from 'src/helpers/storage';
import { useAuth } from 'src/hooks/useAuth';
import useWindowSize from 'src/hooks/useWindowSize';
import i18n from 'src/i18n/i18n';
import { THEME_MODE } from 'src/interfaces/theme';
import { setTheme } from 'src/store/actions/theme';
import { LANGUAGE_CODE, languages } from './ProfileDetail/MultiLanguageContent';
import './styles.scss';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { ReactComponent as AppLogo } from 'src/assets/icons/header/axor/axor-logo.svg';
import { ReactComponent as DropdownIcon } from 'src/assets/icons/header/axor/dropdown-ic.svg';
import ButtonContained from 'src/components/02.buttons/ButtonContained';
import { PATHS } from 'src/constants/paths';
import { ellipseAddress } from 'src/helpers';
import { RootState } from 'src/store';
import { getBalanceAssets, setCurrentAccount } from 'src/store/actions/account';
import { setAccessTokenRedux } from 'src/store/actions/user';
import {
  setBalanceAll,
  setBalanceAvailable,
  setBalanceInOrder,
} from 'src/store/actions/wallet-analytic';

import { AuthenticationStatus, ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import { UserIcon } from 'src/assets/icons/IconComponent';
import { ReactComponent as ChangeThemeIcon } from 'src/assets/icons/header/axor/change-theme-ic.svg';
import { ReactComponent as ChangeThemeDarkIcon } from 'src/assets/icons/header/axor/change-theme-dark-ic.svg';

import MenuIcon from 'src/assets/icons/navbar/MenuIcon';
import ConnectingModal from 'src/components/06.modals/ConnectingModal';
import { BaseSocket } from 'src/socket/BaseSocket';
import { getInstrument } from 'src/store/actions/instrument';
import { useConnectWallet } from 'src/web3/hooks';
import { useAccount, useAccountEffect } from 'wagmi';
import { blockedUserCode } from 'src/constants';
import { getMasterDataFuture } from 'src/store/actions/masterdataFuture';
import UserAgreementModal from 'src/components/06.modals/UserAgreementModal';

const { Header } = Layout;

const logoMobileWidth = 90;
const logoDesktopWidth = 107;

export interface ConnectButtonProps {
  account?: {
    address: string;
    balanceDecimals?: number;
    balanceFormatted?: string;
    balanceSymbol?: string;
    displayBalance?: string;
    displayName: string;
    ensAvatar?: string;
    ensName?: string;
    hasPendingTransactions: boolean;
  };
  chain?: {
    hasIcon: boolean;
    iconUrl?: string;
    iconBackground?: string;
    id: number;
    name?: string;
    unsupported?: boolean;
  };
  mounted: boolean;
  authenticationStatus?: AuthenticationStatus;
  openAccountModal: () => void;
  openChainModal: () => void;
  openConnectModal: () => void;
  accountModalOpen: boolean;
  chainModalOpen: boolean;
  connectModalOpen: boolean;
}

interface Props {
  isOpenMenuMobile: boolean;
  setIsOpenMenuMobile: (bool: boolean) => void;
}

const AppHeader: React.FC<Props> = ({ isOpenMenuMobile, setIsOpenMenuMobile }) => {
  const {
    isAuth,
    setWrongChain,
    wrongChain,
    isAuthChecking,
    isWaitForSignIn,
    resetSignLoading,
    signIn,
  } = useAuth();
  const { t } = useTranslation('translations');
  const { disconnectWallet, resetStorage } = useConnectWallet();
  const { openConnectModal } = useConnectModal();

  const userTheme = useSelector((state: RootState) => state.theme);
  const currentInstrument = useSelector((state: RootState) => state.instrument.currentInstrument);
  const { isMobile } = useWindowSize();

  const { address, connector } = useAccount();

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const userLoginSuccess = useMemo(() => {
    return isAuth && !isEmpty(address) && !wrongChain && !isAuthChecking;
  }, [isAuth, address, wrongChain, isAuthChecking]);

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      document.documentElement.setAttribute('data-theme', THEME_MODE.LIGHT.toLocaleLowerCase());
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      document.documentElement.setAttribute('data-theme', THEME_MODE.DARK.toLocaleLowerCase());
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const langArr = useMemo(() => {
    return Object.keys(languages)
      ?.map((item: any) => item)
      ?.map((lang: any) => ({ langCode: lang, value: lang, ...languages[lang] }));
  }, []);

  const handleChangeLanguage = (item: any) => {
    // setLanguage(item);
    setStorageLanguage(item);
    i18n.changeLanguage(item);
  };

  const renderRightMenuAction = (userTheme: any) => {
    return (
      <div className="menu-language-theme">
        <div className="change-language">
          <FracSelectWithLanguage
            suffixIcon={<DropdownIcon color={userTheme === THEME_MODE.DARK ? '#fff' : '#333333'} />}
            className="frac-select-language"
            popupClassName="frac-select-language-popup"
            value={languages[i18n.resolvedLanguage || LANGUAGE_CODE.ENGLISH]?.label}
            code={i18n.resolvedLanguage}
            options={langArr}
            onChange={handleChangeLanguage}
            zindexicon={1}
          />
        </div>
        <span onClick={handleChangeTheme} className="change-theme cursor-pointer">
          {userTheme === THEME_MODE.DARK ? (
            <ChangeThemeDarkIcon color={userTheme === THEME_MODE.DARK ? '#fff' : '#333333'} />
          ) : (
            <ChangeThemeIcon color={userTheme === THEME_MODE.DARK ? '#fff' : '#333333'} />
          )}
        </span>
      </div>
    );
  };

  const handleRetryConnect = async () => {
    localStorage.removeItem('wagmi.metaMask.disconnected');
    await disconnectWallet(false);
    openConnectModal?.();
    resetSignLoading();
  };

  const renderConnectButton = ({
    chain,
    openAccountModal,
    openChainModal,
    openConnectModal,
    account,
  }: ConnectButtonProps) => {
    if (chain?.unsupported && isAuth && !!account?.address) {
      setWrongChain(true);
      return (
        <div className="info-section">
          <ButtonContained className="wrong-network" onClick={openChainModal}>
            Wrong network
          </ButtonContained>
          {renderRightMenuAction(userTheme)}
        </div>
      );
    } else {
      setWrongChain(false);
    }

    return (
      <div className="info-section">
        {isAuthChecking && !isWaitForSignIn && (
          <ButtonContained className="connect-btn" loading={true}>
            Loading...
          </ButtonContained>
        )}
        {!userLoginSuccess && !isAuthChecking && account?.address && (
          <ButtonContained
            onClick={async () => {
              try {
                resetStorage();
                await signIn({
                  connector,
                  address: account.address,
                });
                history.push(PATHS.exchange());
              } catch (error: any) {
                if (blockedUserCode.includes(error?.response?.data?.code)) {
                  history.push(PATHS.restricted());
                }
              }
            }}
            className="connect-btn"
          >
            {t('commons.header.connectWallet')}
          </ButtonContained>
        )}
        {userLoginSuccess && (
          <div className="info-section">
            <ButtonOutLined
              className={`bt-portfolio bt-portfolio--${userTheme}`}
              noBorder
              onClick={() => history.push(PATHS.overview())}
            >
              {t('commons.header.portfolio')}
            </ButtonOutLined>
            <div
              className="info-section__user"
              onClick={() => {
                openAccountModal?.();
              }}
            >
              <UserIcon color={userTheme === 'Dark' ? null : '#333333'} />
              {!isMobile && (
                <span className={`address-${userTheme}`}>{ellipseAddress(address, 6, 4)}</span>
              )}
            </div>
          </div>
        )}
        {!userLoginSuccess && !isAuthChecking && !account?.address && (
          <ButtonContained
            className="connect-btn"
            onClick={async () => {
              localStorage.removeItem('wagmi.metaMask.disconnected');
              await disconnectWallet(false);
              openConnectModal?.();
            }}
          >
            {t('commons.header.connectWallet')}
          </ButtonContained>
        )}
        {renderRightMenuAction(userTheme)}
      </div>
    );
  };

  const handleClickLogo = () => {
    const formatSymbol = currentInstrument.symbol.replace('/', '');
    history.push(`${PATHS.exchange()}/${formatSymbol}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const resetStore = (accessToken: string, accountSelected: string) => {
    dispatch(setAccessTokenRedux(accessToken));
    dispatch(setCurrentAccount(accountSelected as string));
    dispatch(setBalanceAvailable([]));
    dispatch(setBalanceInOrder([]));
    dispatch(setBalanceAll([]));
  };

  useAccountEffect({
    onDisconnect: () => {
      resetStore('', '');
      disconnectWallet(false);
    },
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    dispatch(getInstrument());
    if (BaseSocket.getToken() !== getStorageJwtToken()) {
      if (userLoginSuccess) {
        BaseSocket.getInstance().reconnect(getStorageJwtToken()!);
      } else {
        BaseSocket.getInstance().reconnect();
      }
    } else if (BaseSocket.getToken() === null) {
      BaseSocket.getInstance().connect();
    }

    dispatch(getMasterDataFuture());
    if (userLoginSuccess) {
      dispatch(getBalanceAssets());
      BaseSocket.getInstance().listenBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginSuccess]);

  return (
    <Header className={`site-layout-header ${userTheme}`}>
      <div className="sidebar-wrapper">
        {userLoginSuccess && location.pathname.includes('portfolio') && (
          <div
            className={`icon-menu-${userTheme} ${isOpenMenuMobile ? 'bg-dark' : ''}`}
            onClick={() => {
              setIsOpenMenuMobile(!isOpenMenuMobile);
            }}
          >
            <MenuIcon color={'#878787'} isOpen={isOpenMenuMobile} />
          </div>
        )}
        <div className="logo-section" onClick={() => handleClickLogo()}>
          <AppLogo
            width={!isMobile ? logoDesktopWidth : logoMobileWidth}
            color={userTheme === THEME_MODE.DARK ? '#fff' : '#002E26'}
          />
        </div>
      </div>
      <ConnectButton.Custom>{renderConnectButton}</ConnectButton.Custom>
      <ConnectingModal onCancel={handleRetryConnect} open={isWaitForSignIn} />
      <UserAgreementModal />
    </Header>
  );
};

export default React.memo(AppHeader);
