import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'src/hooks/useAuth';
import useWindowSize from 'src/hooks/useWindowSize';
import { ConnectorKey, trustWalletConnect, walletConnect } from 'src/web3/connectors';
import { CONNECTOR_KEY } from 'src/web3/constants/storages';
import AxorModal, { IFracModalProps } from '.';
import { fracToast2 } from '../07.toast';
import './styles/connect-wallet-modal.scss';

import { useTranslation } from 'react-i18next';
import CoinbaseIc from 'src/assets/icons/header/axor/coinbase-ic.svg';
import MetamaskIc from 'src/assets/icons/header/axor/metamask-ic.svg';
import TrustIc from 'src/assets/icons/header/axor/trust-wallet.svg';
import WalletConnectIc from 'src/assets/icons/header/axor/wallet-connect-ic.svg';
import { setStorageLogKey } from 'src/helpers/storage';
import { setCurrentConnector } from 'src/store/actions/user';
import { SET_USER_LOGIN_LOADING } from 'src/store/constants/user';
import { coinbaseWallet } from 'src/web3/connectors/coinbase';

interface IConnectWalletModalProps extends IFracModalProps {
  closeModal?: () => void;
  onLoginSuccess?: () => void;
  setIsOpenDeactivatedModal?: React.Dispatch<React.SetStateAction<boolean>>;
  onLoading?: (value: boolean) => void;
}
interface IConnectBoxProps {
  text: string;
  icon: string;
  isActive: boolean;
  onClick: () => void;
}

const ConnectBox: React.FC<IConnectBoxProps> = (props: IConnectBoxProps) => {
  const { icon, isActive, onClick, text } = props;

  return (
    <div className={`box-connect ${isActive ? 'active-connect' : ''}`} onClick={onClick}>
      <div className="group-content">
        <img alt="wallet icon" src={icon} />
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

const ConnectWalletModal: React.FC<IConnectWalletModalProps> = (
  props: IConnectWalletModalProps,
) => {
  const { isAuth } = useAuth();
  const connectorName = localStorage.getItem(CONNECTOR_KEY);
  const userTheme = useSelector((state: any) => state.theme);
  const { isMobile } = useWindowSize();
  const { open, closeModal, onLoginSuccess } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation('translations');
  // states

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal();
    }
  };

  const isMetaMaskInstalled = () => {
    return Boolean((window as any).ethereum && (window as any).ethereum.isMetaMask);
  };

  const clearWalletConnect = () => {
    coinbaseWallet.resetState();
    coinbaseWallet.deactivate();
    walletConnect.resetState();
    walletConnect.deactivate();
    trustWalletConnect.resetState();
    trustWalletConnect.deactivate();
    localStorage.clear();
  };

  const handleClick = async (connectorKey: ConnectorKey) => {
    dispatch({
      type: SET_USER_LOGIN_LOADING,
      payload: true,
    });
    dispatch(setCurrentConnector(connectorKey));

    try {
      if (!isMetaMaskInstalled() && connectorKey === ConnectorKey.metaMask) {
        const metamaskAppDeepLink = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
        if (isMobile) {
          window.location.assign(metamaskAppDeepLink);
        } else {
          window.open(metamaskAppDeepLink);
        }
        return;
      }

      if ([ConnectorKey.walletConnect, ConnectorKey.trustWallet].includes(connectorKey)) {
        clearWalletConnect();
      }
      // await signIn(connectorKey, () => {
      //   props.onLoading?.(true);
      // });
      onLoginSuccess && onLoginSuccess();
      handleCloseModal();
      setStorageLogKey();
    } catch (error: any) {
      if (!error.message.includes('Connection request reset')) {
        fracToast2.error(
          t('translations:messages.errors.failedToConnect'),
          t('translations:messages.errors.failedToConnect'),
        );
      }
    } finally {
      dispatch({
        type: SET_USER_LOGIN_LOADING,
        payload: false,
      });
    }
    props.onLoading?.(false);
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  return (
    <AxorModal
      showCloseIcon={true}
      open={open}
      onCancel={handleCancel}
      width={620}
      title={'Select Wallet'}
      wrapClassName={`connect-wallet-modal connect-wallet-modal-${userTheme}`}
    >
      <div className="type-connect-group">
        <ConnectBox
          icon={MetamaskIc}
          onClick={() => {
            handleClick(ConnectorKey.metaMask);
          }}
          text="Metamask"
          isActive={isAuth && connectorName === ConnectorKey.metaMask}
        />
        <ConnectBox
          icon={TrustIc}
          onClick={() => {
            handleClick(ConnectorKey.trustWallet);
          }}
          text="Trust"
          isActive={isAuth && connectorName === ConnectorKey.trustWallet}
        />
        <ConnectBox
          icon={CoinbaseIc}
          onClick={() => {
            handleClick(ConnectorKey.coinBase);
          }}
          text="CoinBase"
          isActive={isAuth && connectorName === ConnectorKey.coinBase}
        />
        <ConnectBox
          icon={WalletConnectIc}
          onClick={() => {
            handleClick(ConnectorKey.walletConnect);
          }}
          text="WalletConnect"
          isActive={isAuth && connectorName === ConnectorKey.walletConnect}
        />
      </div>
    </AxorModal>
  );
};

export default ConnectWalletModal;
