import { Web3ReactHooks } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { metaMask, metamaskHooks } from './metmask';
import { handleGenerateConnector } from './walletConnect';
import { coinbaseWallet, coinbaseWalletHooks } from './coinbase';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';

export enum ConnectorKey {
  metaMask = 'injected',
  walletConnect = 'walletConnect',
  trustWallet = 'trustWallet',
  coinBase = 'coinbaseWallet',
}

export const walletKeys = {
  [ConnectorKey.trustWallet]: ['4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'],
  [ConnectorKey.metaMask]: ['c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'],
  [ConnectorKey.coinBase]: ['fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'],
  [ConnectorKey.walletConnect]: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  ],
};

const { walletConnect: trustWalletConnect, walletConnectHooks: trustWalletConnectHooks } =
  handleGenerateConnector(ConnectorKey.trustWallet);
const { walletConnect, walletConnectHooks } = handleGenerateConnector(ConnectorKey.walletConnect);

export const connectors = {
  [ConnectorKey.metaMask]: metaMask,
  [ConnectorKey.walletConnect]: walletConnect,
  [ConnectorKey.trustWallet]: trustWalletConnect,
  [ConnectorKey.coinBase]: coinbaseWallet,
};

export { trustWalletConnect, walletConnect };

export const appConnectors: [MetaMask | CoinbaseWallet | WalletConnect, Web3ReactHooks][] = [
  [metaMask, metamaskHooks],
  [walletConnect, walletConnectHooks],
  [trustWalletConnect, trustWalletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
];
