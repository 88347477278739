import { get } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OrderService } from 'src/services/order-service';
import { IOrderBook } from 'src/services/params-type';
import {
  CLEAR_ORDER_BOOK,
  INIT_ORDER_BOOK,
  PENDING_ORDER_BOOK,
  UPDATE_ORDER_BOOK,
} from 'src/store/constants/order-book';
import { IOrderbook } from '../reducers/order-book';

interface IOrderBookStore {
  bids: IOrderBook[];
  asks: IOrderBook[];
}

export const initOrderBook = (payload: IOrderBookStore) => {
  return {
    type: INIT_ORDER_BOOK,
    payload,
  };
};

export const updateOrderBook = (payload: IOrderbook) => {
  return {
    type: UPDATE_ORDER_BOOK,
    payload,
  };
};

export const updatePendingOrderBook = (payload: boolean) => {
  return {
    type: PENDING_ORDER_BOOK,
    payload,
  };
};

export const clearOrderBook = () => {
  return {
    type: CLEAR_ORDER_BOOK,
  };
};
export const fetchOrderBook = (pairSymbol: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(clearOrderBook());

    const orderBookServices = new OrderService();
    try {
      dispatch(updatePendingOrderBook(true));

      const res = await orderBookServices.getListOrderBook(pairSymbol);

      const asks = get(res, 'data.asks', []);
      const bids = get(res, 'data.bids', []);

      dispatch(
        initOrderBook({
          asks,
          bids,
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(clearOrderBook());
    }
    dispatch(updatePendingOrderBook(false));
  };
};
