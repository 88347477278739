import { Button, ButtonProps } from "antd";
import React from "react";
import "./styles/button-contained.scss";

interface IButtonContainedProps extends ButtonProps {
  className?: "contained-purple" | string;
}

const ButtonContained: React.FC<IButtonContainedProps> = (
  props: IButtonContainedProps
) => {
  const { className, children, size ,...restprops } = props;
  return (
    <Button className={`contained-default contained-default--${size || 'small'} ${className || ''}`} {...restprops}>
      {children}
    </Button>
  );
};

export default ButtonContained;
