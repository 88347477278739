import { ServiceBase } from './core/service-base';
export class AuthServices extends ServiceBase {
  // Implement method call API
  login = async (params: { walletAddress: string; signature: string }) => {
    return this.post('/auth/login', params);
  };

  refreshToken = async (params: { refreshToken: string }) => {
    return this.post('/auth/refresh-token', params);
  };
}
