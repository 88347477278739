import { ServiceBase } from './core/service-base';
import { Favorites } from './params-type';

export class FavoriteService extends ServiceBase {
  getFavorite = () => {
    return this.get(`/users/favorite`);
  };

  getFeeLevel = () => {
    return this.get(`/users/fee-level`);
  };

  addFavorite = (params: Favorites) => {
    return this.post(`/users/add-favorite`, params);
  };

  removeFavorite = (params: Favorites) => {
    return this.deleteWithBody(`/users/favorite`, params);
  };
}
