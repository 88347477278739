import { watchAccount } from '@wagmi/core';
import { useEffect } from 'react';
import { useConfig } from 'wagmi';
import { useAuth } from 'src/hooks/useAuth';
import { REACT_APP_CHAIN_ID } from '../constants/envs';
import { useHistory } from 'react-router';
import { blockedUserCode } from 'src/constants';
import { PATHS } from 'src/constants/paths';

export function useWalletListener() {
  const config = useConfig();
  const { signIn } = useAuth();
  const history = useHistory();
  useEffect(() => {
    const unwatchAccount = watchAccount(config, {
      onChange(data, previousAccount) {
        if (data.status === 'connected' && previousAccount?.address !== data?.address) {
          setTimeout(async () => {
            try {
              await signIn({
                ...data,
                chainId: data.chainId !== 0 ? data.chainId : Number(REACT_APP_CHAIN_ID),
              });
              history.push(PATHS.exchange());
            } catch (error: any) {
              if (blockedUserCode.includes(error?.response?.data?.code)) {
                history.push(PATHS.restricted());
              }
            }
          }, 1000);
        }
      },
    });
    return () => {
      unwatchAccount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
}
