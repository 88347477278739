import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

const IntercomHelpCenter = () => {
  const appId = process.env.REACT_APP_INTRACOM_APP__ID
    ? process.env.REACT_APP_INTRACOM_APP__ID
    : '';
  useEffect(() => {
    Intercom({
      app_id: appId,
    });
  }, [appId]);

  return <></>;
};

export default IntercomHelpCenter;
