import { ServiceBase } from './core/service-base';
import {
  BalanceInOrderParams,
  CancelAllOrderParams,
  CreateOrderParams,
  IListOrderHistoryParams,
  ListOrderParams
} from './params-type';

export class OrderService extends ServiceBase {
  getListOrderBook = (pairSymbol: string) => {
    const encodedSymbol = encodeURIComponent(pairSymbol);
    return this.get('/orderbook/' + encodedSymbol);
  };

  createOrder = (params: CreateOrderParams) => {
    return this.post('/order', params);
  };

  getListOrders = (params: ListOrderParams) => {
    return this.get('/order/open', params);
  };

  cancelOrder = (id: number) => {
    return this.delete(`/order`, id);
  };

  cancelAllOrders = (params: CancelAllOrderParams) => {
    return this.deleteWithParams(`order/cancel-order`, params);
  };

  getExchangeRate = () => {
    return this.get(`/misc/exchange-rates`);
  };

  getBalanceInOrder = (params: BalanceInOrderParams) => {
    return this.get(`/order/balances-in-order`, params);
  };

  getVolatility = () => {
    return this.get(`users/volatility`);
  };

  getConfidenceInterval = () => {
    return this.get(`/users/confidence`);
  };

  getIntervalSettings = (params: any) => {
    return this.get('/users/get-interval-settings', params);
  };
  getViewOrder = (rootOrderId: number) => {
    return this.get(`/order/tp-sl/${rootOrderId}`);
  };
  getOrderHistory = (params: IListOrderHistoryParams) => {
    return this.get('/order/history', params);
  };

  updateTpSl = (id: number | string, payload: any | undefined) => {
    return this.put(`/order/tp-sl/${id}`, payload);
  };
}
