import { Modal, ModalProps } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_MODAL_WIDTH } from 'src/constants';
import './styles/index.scss';
import { SET_IS_OPEN_MODAL } from 'src/store/constants/general';
import { THEME_MODE } from 'src/interfaces/theme';
import { CloseIcon } from 'src/assets/icons/IconComponent';

export interface IFracModalProps extends ModalProps {
  children?: any;
  showCloseIcon?: boolean;
  forceBodyOverflowHidden?: boolean;
}

const AxorModal: React.FC<IFracModalProps> = (props: IFracModalProps) => {
  const { children, footer, width, wrapClassName, showCloseIcon, forceBodyOverflowHidden } = props;
  const userTheme = useSelector((state: any) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    if (forceBodyOverflowHidden) {
      dispatch({
        type: SET_IS_OPEN_MODAL,
        payload: props.open,
      });
    }
    if (props.open === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, forceBodyOverflowHidden]);

  return (
    <Modal
      closeIcon={<></>}
      // maskClosable={false}
      closable={props?.onCancel ? true : false}
      width={width ? width : DEFAULT_MODAL_WIDTH}
      footer={footer ? footer : null}
      destroyOnClose={true}
      {...props}
      wrapClassName={`${wrapClassName ?? ''} frac-modal-wrapper frac-modal-wrapper-${userTheme}`}
    >
      {showCloseIcon && (
        <div className="frac-modal-wrapper__header">
          <div onClick={props?.onCancel} className="frac-modal-wrapper__close">
            <CloseIcon color={userTheme === THEME_MODE.DARK ? '#373743' : ''} />
          </div>
        </div>
      )}
      {children}
    </Modal>
  );
};

export default AxorModal;
