import '@ant-design/flowchart/dist/index.css';
import '@rainbow-me/rainbowkit/styles.css';
import 'antd/dist/antd.css';
import BigNumber from 'bignumber.js';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'src/assets/scss/_themes.scss';
import 'src/assets/scss/variable.scss';
import Layout from './components/01.layout';
import LocaleProviderComponent from './components/15.locale-provider';
import ToastContext from './contexts/toast';
import Routes from './routes/routes';

import { RainbowKitProvider, darkTheme, lightTheme } from '@rainbow-me/rainbowkit';
import { useSelector } from 'react-redux';
import { THEME_MODE } from './interfaces/theme';
import { RootState } from './store';
import './index.scss';
import './App.scss';

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
});

const App: React.FC = () => {
  const theme: THEME_MODE = useSelector((state: RootState) => state.theme);

  return (
    <RainbowKitProvider theme={theme === THEME_MODE.DARK ? darkTheme() : lightTheme()}>
      <LocaleProviderComponent>
        <BrowserRouter>
          <Layout>
            <ToastContext />
            <Routes />
          </Layout>
        </BrowserRouter>
      </LocaleProviderComponent>
    </RainbowKitProvider>
  );
};

export default App;
