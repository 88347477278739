import { ToastContainer } from 'react-toastify';
import { ToastCloseIcon, ToastInfoIcon } from 'src/assets/icons';
import SuccessIcon from 'src/assets/icons/toast/success-ic.svg';
import ErrorIcon from 'src/assets/icons/toast/toast-failed-icon.svg';

import './toast.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { THEME_MODE } from 'src/interfaces/theme';

const ToastContext = () => {
  const theme = useSelector((state: RootState) => state.theme);
  return (
    <ToastContainer
      icon={(props) => {
        switch (props.type) {
          case 'info':
            return <img src={ToastInfoIcon} alt="Toast-info-icon" className="toast-icon" />;
          case 'error':
            return (
              <img src={ErrorIcon} alt="Toast-info-icon" className="toast-icon toast-error-icon" />
            );
          case 'success':
            return (
              <img
                src={SuccessIcon}
                alt="Toast-info-icon"
                className="toast-icon toast-success-icon"
              />
            );
          case 'warning':
            return (
              <img src={ErrorIcon} alt="Toast-info-icon" className="toast-icon toast-error-icon" />
            );
          case 'default':
            return (
              <img
                src={ToastInfoIcon}
                alt="Toast-info-icon"
                className="toast-icon toast-info-icon"
              />
            );
          default:
            return <img src={ToastInfoIcon} alt="Toast-info-icon" className="toast-icon" />;
        }
      }}
      closeButton={() => (
        <img src={ToastCloseIcon} alt="Toast close" className="toast-close-icon" />
      )}
      bodyClassName={`body-toast ${
        theme === THEME_MODE.DARK ? 'body-toast--dark' : 'body-toast--light'
      }`}
      toastClassName={(props) => {
        switch (props?.type) {
          case 'info':
            return 'wrapper-toast';
          case 'error':
            return 'wrapper-toast wrapper-error-toast';
          case 'success':
            return 'wrapper-toast wrapper-success-toast';
          case 'warning':
            return 'wrapper-toast wrapper-error-toast';
          case 'default':
            return 'wrapper-toast';
          default:
            return 'wrapper-toast';
        }
      }}
      autoClose={3000}
      draggable={false}
      hideProgressBar
      pauseOnHover={false}
      position="top-right"
      limit={10}
    />
  );
};

export default ToastContext;
