import Icon from '@ant-design/icons';
import { Input, Select, SelectProps } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ArrowDownSelectIconComponent,
  ClearIcon,
  DownIconOrder,
  SearchPairTableIconComponent,
} from 'src/assets/icons/IconComponent';
import useWindowSize from 'src/hooks/useWindowSize';
import './styles.scss';
import { THEME_MODE } from 'src/interfaces/theme';

export type option = { value: string | number; label: string };

interface ISelect extends SelectProps {
  zindexicon?: number;
  placeholder?: string;
  isShowPlaceholder?: boolean;
  optionsdefault?: any;
}

const { Search } = Input;
const { Option } = Select;

const suffixIcon = (zindexicon = -1) => {
  return (
    <Icon
      className="item-icon profile-detail-icon"
      style={{ zIndex: Number(zindexicon) }}
      component={ArrowDownSelectIconComponent}
    />
  );
};

const suffixIconMobile = (userTheme: any) => {
  return <DownIconOrder />;
};

const FracSelect: React.FC<ISelect> = (props: ISelect) => {
  const { options = [], className, popupClassName } = props;

  const userTheme = useSelector((state: any) => state.theme);

  return (
    <Select
      {...props}
      placement="bottomLeft"
      className={`frac-select ${className} frac-select-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
      suffixIcon={suffixIconMobile(userTheme)}
      onDropdownVisibleChange={(open) => {
        if (open) {
          document.body.style.overflow = 'hidden';
          return false;
        } else {
          document.body.style.overflow = '';
          return false;
        }
      }}
      popupClassName={
        popupClassName
          ? `frac-select-popup-common-${userTheme} ${popupClassName}`
          : `frac-select-popup-common-${userTheme}`
      }
    >
      {options?.map((option: any, index: number) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default FracSelect;

export const FracSelectSearch: React.FC<ISelect> = (props: ISelect) => {
  const { optionsdefault = [], className, popupClassName, placeholder } = props;
  const [searchValue, setSearchValue] = useState('');
  const userTheme = useSelector((state: any) => state.theme);

  const filteredOptions = optionsdefault.filter((option: any) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <Select
      {...props}
      placement="bottomLeft"
      className={`frac-select ${className} frac-select-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
      suffixIcon={suffixIconMobile(userTheme)}
      popupClassName={
        popupClassName
          ? `frac-select-popup-common-${userTheme} ${popupClassName}`
          : `frac-select-popup-common-${userTheme}`
      }
      onDropdownVisibleChange={(open) => {
        if (open) {
          // document.body.style.overflow = 'hidden';
          return false;
        } else {
          setSearchValue('');
          // document.body.style.overflow = '';
          return false;
        }
      }}
      dropdownRender={(menu) => (
        <div>
          <Search
            placeholder={placeholder || 'Search...'}
            allowClear={{
              clearIcon: <ClearIcon color={userTheme === THEME_MODE.LIGHT ? '#D9DAE7' : null} />,
            }}
            value={searchValue}
            className={`input-search-${userTheme}`}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: '100%', marginBottom: 8 }}
          />
          {filteredOptions.length > 0 ? menu : <div className="no-result">No results found</div>}
        </div>
      )}
      options={filteredOptions}
    ></Select>
  );
};

interface ISelectCustom extends SelectProps {
  label: string;
}

export const FracSelectWithLabel: React.FC<ISelectCustom> = (props: ISelectCustom) => {
  const { options = [], className, label } = props;

  const userTheme = useSelector((state: any) => state.theme);

  return (
    <div
      className={`select-custom frac-select-with-label-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
    >
      <label>{label}: </label>
      <Select
        {...props}
        className={`${className ? className : 'frac-select'}`}
        suffixIcon={suffixIcon()}
        // onDropdownVisibleChange={(open) => {
        //   if (open) {
        //     document.body.style.overflow = 'hidden';
        //     return false;
        //   } else {
        //     document.body.style.overflow = '';
        //     return false;
        //   }
        // }}
      >
        {options?.map((option: any, index: number) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export const FracSelectWithSearch: React.FC<ISelect> = (props: ISelect) => {
  const { options = [], className, popupClassName } = props;

  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();

  const userTheme = useSelector((state: any) => state.theme);

  const [isFocus, setIsFocus] = useState<any>(false);

  const ref = useRef<any>(null);

  function checkRegex(evt: any) {
    const value = evt.target.value.trim();
    const regex = new RegExp(/^[a-zA-Z0-9/?]*$/);
    if (!regex.test(evt.key) || value.length > 29) {
      evt.preventDefault();
    }
  }

  const handleFocus = (e: any) => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  const handleSelect = () => {
    ref.current?.blur();
    handleBlur();
  };

  const handleKeyDown = (e: any) => {
    checkRegex(e);
  };

  return (
    <div
      className={`${isFocus ? 'custom-select-search' : ''} frac-select-with-search-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
    >
      {isFocus && (
        <Icon
          className={`search-icon`}
          component={SearchPairTableIconComponent}
          alt="search-icon"
        />
      )}
      <Select
        {...props}
        value={isFocus ? undefined : props.value}
        notFoundContent={t('dex.exchange.orderHistory.noRecordFound')}
        onFocus={handleFocus}
        onInputKeyDown={handleKeyDown}
        ref={ref}
        onSelect={handleSelect}
        onBlur={handleBlur}
        className={`${className ? className : 'frac-select'} frac-select-with-label-${userTheme}`}
        suffixIcon={isDesktop ? suffixIcon() : suffixIconMobile(userTheme)}
        popupClassName={popupClassName ? popupClassName : `frac-select-popup-common-${userTheme}`}
      >
        {options?.map((option: any, index: number) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

interface ISelectLanguage extends SelectProps {
  zindexicon?: number;
  code: string;
}

export const FracSelectWithLanguage: React.FC<ISelectLanguage> = (props: ISelectLanguage) => {
  const {
    options = [],
    className,
    popupClassName,
    zindexicon = -1,
    code,
    suffixIcon: SuffIcon,
  } = props;

  const { isDesktop } = useWindowSize();

  const userTheme = useSelector((state: any) => state.theme);

  return (
    <Select
      {...props}
      value={code}
      dropdownMatchSelectWidth={120}
      className={`frac-select ${className} frac-select-${userTheme} ${
        props.disabled ? 'disable-frac-select' : ''
      } ${userTheme}`}
      suffixIcon={
        SuffIcon ? SuffIcon : isDesktop ? suffixIcon(zindexicon) : suffixIconMobile(userTheme)
      }
      popupClassName={
        popupClassName
          ? `frac-select-popup-common-${userTheme} ${popupClassName}`
          : `frac-select-popup-common-${userTheme}`
      }
    >
      {options?.map((option: any, index: number) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
