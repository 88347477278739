// tickerReducers.ts
import { IOrder } from 'src/types/orders';
import * as types from '../constants/positions';

export interface IPositions {
  id: number | string;
  accountId: number;
  asset: string;
  ownerEmail: string;
  managerEmail: string;
  symbol: string;
  leverage: string;
  unrealisedPnl: string;
  currentQty: string;
  riskLimit: string;
  riskValue: string;
  initMargin: string;
  maintainMargin: string;
  extraMargin: string;
  requiredInitMarginPercent: string;
  requiredMaintainMarginPercent: string;
  liquidationPrice: string;
  bankruptPrice: string;
  entryPrice: string;
  entryValue: string;
  openOrderBuyQty: string;
  openOrderSellQty: string;
  openOrderBuyValue: string;
  openOrderSellValue: string;
  multiplier: string;
  liquidationProgress: boolean;
  liquidationOrderId: number;
  isCross: boolean;
  pnlRanking: string;
  closedId: number;
  closedPrice: string;
  createdAt: string;
  updatedAt: string;
  openOrderValue: string;
  openOrderMargin: string;
  netFunding?: string;
  maxLiquidationBalance: string;
  realisedPnl: string;
  latestRealisedPnl: string;
  operationId: string;
  adjustMargin: string;
  takeProfitOrderId: number;
  stopLossOrderId: number;
  orders: IOrder[];
  contractType: string;
}

const initialState: {
  loading: boolean;
  error: boolean;
  allPosition: IPositions[];
  positions: IPositions[];
  totalPage: number;
  totalItem: number;
  currentPage: number;
  currentSymbol: string;
} = {
  loading: false,
  error: false,
  allPosition: [],
  positions: [],
  totalPage: 0,
  totalItem: 0,
  currentPage: 1,
  currentSymbol: '',
};

const positionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_POSITIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_POSITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        positions: action.payload.positions,
        currentPage: action.payload.currentPage,
        totalPage: action.payload.totalPages,
        totalItem: action.payload.totalItems,
      };
    case types.GET_POSITIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.UPDATE_ALL_POSITION:
      return {
        ...state,
        allPosition: action.payload,
      };
    case types.UPDATE_POSITIONS:
      return {
        ...state,
        positions: action.payload.positions,
        totalItem: action.payload.newTotalItem,
        totalPage: action.payload.newTotalPage,
      };
    default:
      return state;
  }
};

export default positionsReducer;
