import { ServiceBase } from './core/service-base';

export class SiteSettingService extends ServiceBase {
  getSocialSettings = () => {
    return this.get('/site-setting/social');
  };
  getSettingsGeneral = () => {
    return this.get('/site-setting/general');
  };
}
