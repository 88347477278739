// tickerActions.ts
import { TickerService } from 'src/services/ticker-service';
import {
  GET_TICKER_24H_FAILURE,
  GET_TICKER_24H_REQUEST,
  GET_TICKER_24H_SUCCESS,
  UPDATE_TICKERS,
} from '../constants/ticker';

export const getTicker24hRequest = () => ({ type: GET_TICKER_24H_REQUEST });
export const getTicker24hSuccess = (payload: any) => ({ type: GET_TICKER_24H_SUCCESS, payload });
export const getTicker24hFailure = (error: any) => ({
  type: GET_TICKER_24H_FAILURE,
  payload: error,
});
export const updateTickers = (payload: any) => ({ type: UPDATE_TICKERS, payload });

export const getTicker24h = (params?: any) => async (dispatch: any) => {
  dispatch(getTicker24hRequest());
  try {
    const tickerService = new TickerService();

    const response = await tickerService.getTickerById({
      contractType: 'USD_M',
    });
    dispatch(getTicker24hSuccess(response.data || []));
  } catch (error) {
    dispatch(getTicker24hFailure(error));
  }
};
