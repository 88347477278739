import Icon from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ArrowLeftLanguageIconComponent, CheckIconComponent } from 'src/assets/icons/IconComponent';
import { setStorageLanguage } from 'src/helpers/storage';
import i18n from 'src/i18n/i18n';
import { languages } from '../ProfileDetail/MultiLanguageContent';
import './mobile-menu-detail-content.scss';

interface Props {
  language: string;
  onSelectLanguage: (value: string) => void;
  onClose: () => void;
}

const MultiLanguageContent: React.FC<Props> = ({ language, onSelectLanguage, onClose }: Props) => {
  const { t } = useTranslation();
  const userTheme = useSelector((state: any) => state.theme);

  const handleChangeLanguage = (item: any) => {
    if (onSelectLanguage) {
      onSelectLanguage(item.langCode);
    }
    setStorageLanguage(item.langCode);
    i18n.changeLanguage(item.langCode);
  };

  const langArr = Object.keys(languages)
    ?.map((item: any) => item)
    ?.map((lang: any) => ({ langCode: lang, ...languages[lang] }));

  return (
    <div className={`mobile-menu-detail ${userTheme}`}>
      <div className="mobile-menu-detail__item-wrapper pt-20">
        <div
          className="mobile-menu-detail__item-wrapper__item justify-flex-start"
          onClick={onClose}
        >
          <Icon className="back-icon" component={ArrowLeftLanguageIconComponent} />
          <span>{t('layout.header.profileDetail.onHoverLanguage')}</span>
        </div>
      </div>

      {langArr.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`mobile-menu-detail__item-wrapper ${item.className}`}
            onClick={() => {
              handleChangeLanguage(item);
            }}
          >
            <div className="mobile-menu-detail__item-wrapper__item justify-between">
              <span
                className={item.langCode === language ? 'selected-language-item' : 'language-item'}
              >
                {item.label}
              </span>
              <Icon
                className={`check-icon ${item.langCode === language ? '' : 'visibility-hidden'}`}
                component={CheckIconComponent}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultiLanguageContent;
