import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ArrowLeftLanguageIconComponent,
  CheckIconComponent,
  CopyIconVer2Component,
  RedirectIconVer2Component
} from 'src/assets/icons/IconComponent';
import { ellipseAddress } from 'src/helpers';
import { useCopy } from 'src/hooks/useCopy';
import './mobile-menu-detail-content.scss';

export interface IWalletDetailContent {
  address: string;
  onClose: () => void;
  onClickItem: () => void;
}

const WalletContent: React.FC<IWalletDetailContent> = ({ address, onClose, onClickItem }) => {
  const { t } = useTranslation();
  const { copy, copied } = useCopy();
  const account = useSelector((state: any) => state.currentAccount);
  const userTheme = useSelector((state: any) => state.theme);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (copied) return setIsOpen(true);
    return setIsOpen(false);
  }, [copied]);

  const renderContent = () => {
    return (
      <div className={`mobile-menu-detail ${userTheme}`}>
        <div className="mobile-menu-detail__item-wrapper">
          <div className="mobile-menu-detail__item-wrapper__item pt-20 justify-between">
            <div onClick={onClose}>
              <Icon className="back-icon" component={ArrowLeftLanguageIconComponent} />
              <span className="wallet-address">{ellipseAddress(account, 6, 4)}</span>
            </div>
            <div className="icon-group">
              <Tooltip title={t('layout.header.walletDetail.copyTooltip')} open={isOpen}>
                {copied ? (
                  <Icon className="wallet-detail-icon mr-16" component={CheckIconComponent} />
                ) : (
                  <Icon
                    className="wallet-detail-icon mr-16"
                    component={CopyIconVer2Component}
                    onClick={() => copy(account)}
                  />
                )}
              </Tooltip>
              <Icon
                className="wallet-detail-icon"
                component={RedirectIconVer2Component}
                onClick={() => window.open(`https://testnet.bscscan.com/address/${account}`)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{renderContent()}</>;
};

export default WalletContent;
