import { ServiceBase } from './core/service-base';

export class PreferenceService extends ServiceBase {
  getPreference = () => {
    return this.get(`/users/preference`);
  };
  addPreference = (body: any) => {
    return this.post(`/users/add-preference`, body);
  };
  getMe = () => {
    return this.get(`/users/me`);
  };
  acceptAgreement = () => {
    return this.put(`/users/accept-agreement`, {});
  };
}
