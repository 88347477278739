import BigNumber from 'bignumber.js';
import { IS_STABLE_COIN } from 'src/constants/exchange';
import { Instrument } from 'src/interfaces/instrument';

export const KEY_CODE = {
  E: 69, //e
  PLUS: 189, // +
  PLUS_NUMBER: 107, // +
  SUB_TRACTION: 187, // -
  SUB_TRACTION_NUMBER: 109, // -
  ZERO: 98, // 0
  BACK_SPACE: 8, // Backspace
  ARROW_DOWN: 40, // arrow down
  ARROW_UP: 38, // arrow up
  POINT: 190, // .
  NUMBER_POINT: 110, // .
  COMMA: 188, // ,
  EE: 231, // ê
};

export const getNumberRegex = (precision: number): RegExp => {
  return new RegExp(`^\\d{0,100}.\\d{0,${precision}}$`);
};

export const allowNegativeNumberRegex = (precision: number): RegExp => {
  return new RegExp(`^-?\\d{0,100}.\\d{0,${precision}}$`);
};

export const ellipseAddress = (
  address = '',
  maxCharacters = 5,
  maxLastCharacters?: number | undefined,
): string => {
  if (!address) return '';

  return `${address.slice(0, maxCharacters)}...${address.slice(
    -(maxLastCharacters ? maxLastCharacters : maxCharacters),
  )}`;
};

export const decimalCount = (number: any) => {
  const numberAsString = number?.toString();
  if (numberAsString?.includes('.')) {
    return numberAsString?.split('.')?.[1]?.length;
  }
  return 0;
};

export const handleOnKeyDownInputNumber = async (
  event: any,
  regex: any = new RegExp(/^(?!$)\d{0,10}(?:\.\d{1,5})?$/),
) => {
  const value = event.target.value?.toString()?.replaceAll(',', '.');
  const pattern = regex;
  if (
    event.keyCode === KEY_CODE.E ||
    event.keyCode === KEY_CODE.EE ||
    event.keyCode === KEY_CODE.PLUS ||
    event.keyCode === KEY_CODE.PLUS_NUMBER ||
    event.keyCode === KEY_CODE.SUB_TRACTION ||
    event.keyCode === KEY_CODE.SUB_TRACTION_NUMBER ||
    event.keyCode === KEY_CODE.ARROW_DOWN ||
    event.keyCode === KEY_CODE.ARROW_UP ||
    (!pattern?.test(value) && value && event.keyCode !== KEY_CODE.BACK_SPACE) ||
    (!value &&
      (event.keyCode === KEY_CODE.POINT ||
        event.keyCode === KEY_CODE.NUMBER_POINT ||
        event.keyCode === KEY_CODE.COMMA))
  ) {
    event.preventDefault();
  }
};

export const handleOnKeyDownInputPercent = (event: any) => {
  const value = event.target.value?.toString()?.replaceAll(',', '.');
  const pattern = /^(?!$)\d{0,10}(?:\.\d{1,2})?$/;
  // keyCode = 69: e ,189: + ,187: -,8: Backspace,96: 0
  if (
    event.keyCode === KEY_CODE.E ||
    event.keyCode === KEY_CODE.EE ||
    event.keyCode === KEY_CODE.PLUS ||
    event.keyCode === KEY_CODE.PLUS_NUMBER ||
    event.keyCode === KEY_CODE.SUB_TRACTION ||
    event.keyCode === KEY_CODE.SUB_TRACTION_NUMBER ||
    event.keyCode === KEY_CODE.ARROW_DOWN ||
    event.keyCode === KEY_CODE.ARROW_UP ||
    (!pattern.test(value) && value && event.keyCode !== KEY_CODE.BACK_SPACE) ||
    (!value &&
      (event.keyCode === KEY_CODE.POINT ||
        event.keyCode === KEY_CODE.NUMBER_POINT ||
        event.keyCode === KEY_CODE.COMMA))
  ) {
    event.preventDefault();
  }
};

export const handleOnKeyDownInputSlippage = (event: any) => {
  const value = event.target.value?.toString()?.replaceAll(',', '.');
  const pattern = /^(?!$)\d{0,2}(?:\.\d{1,1})?$/;
  // keyCode = 69: e ,189: + ,187: -,8: Backspace,96: 0
  if (
    event.keyCode === KEY_CODE.E ||
    event.keyCode === KEY_CODE.EE ||
    event.keyCode === KEY_CODE.PLUS ||
    event.keyCode === KEY_CODE.PLUS_NUMBER ||
    event.keyCode === KEY_CODE.SUB_TRACTION ||
    event.keyCode === KEY_CODE.SUB_TRACTION_NUMBER ||
    event.keyCode === KEY_CODE.ARROW_DOWN ||
    event.keyCode === KEY_CODE.ARROW_UP ||
    (!pattern.test(value) && value && event.keyCode !== KEY_CODE.BACK_SPACE) ||
    (!value &&
      (event.keyCode === KEY_CODE.POINT ||
        event.keyCode === KEY_CODE.NUMBER_POINT ||
        event.keyCode === KEY_CODE.COMMA))
  ) {
    event.preventDefault();
  }
};

export const isStableCoin = (isFNFTOrCoin: any) => {
  return isFNFTOrCoin === IS_STABLE_COIN;
};

export const generatePrecision = (value: string) => {
  if (value?.includes('.')) {
    return value?.split('.')[1].length;
  } else {
    return 0;
  }
};

export const renderLang = (language: string) => {
  if (language && language?.includes('en')) {
    return 'en';
  }
  if (language && language?.includes('vi')) {
    return 'vi';
  } else {
    return language;
  }
};

export function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export const sliceString = (text: string, end = 30) => {
  if (text?.length > end) return `${text.slice(0, end)}...`;

  return text;
};

export const getPrecision = (precision?: string | number | BigNumber): number | null => {
  if (precision) {
    const p = new BigNumber(Math.log10(Number(precision))).negated();
    return p.isGreaterThanOrEqualTo(0) ? Number(p.toFixed()) : null;
  }
  return null;
};

export const formatValidPrice = (
  price: string | number | BigNumber,
  instrument: Instrument,
): string => {
  if (new BigNumber(price).isNaN()) {
    return '';
  }
  return new BigNumber(price).toFixed(getPrecision(instrument?.tickSize) || 5);
};

export const formatValidPriceNegativeOptinal = (
  price: string | number | BigNumber,
  instrument: Instrument,
  allowNegative = false,
): string => {
  if (new BigNumber(price).isNaN()) {
    return '';
  }
  const result = allowNegative ? new BigNumber(price) : new BigNumber(price).abs();
  return result.toFixed(getPrecision(instrument?.tickSize) || 5);
};

export const isNumber = (str: string): boolean => {
  return !new BigNumber(str).isNaN();
};

export const isStringifyJson = (data: any) => {
  if (!data) {
    return false;
  }
  const regex = new RegExp(/^{".*/g);

  return regex.test(data);
};

export const convertHttpsUrl = (url: string) => {
  const isValidHttpUrl = /^(http(s)?):\/\/.*/.test(url);
  return isValidHttpUrl ? url : 'https://' + url;
};

export const compareToSymbol = (str1: string, str2: string) => {
  const encodedSymbol1 = str1?.replace('/', '').toLocaleLowerCase();
  const encodedSymbol2 = str2?.replace('/', '').toLocaleLowerCase();
  return encodedSymbol1 === encodedSymbol2;
};
