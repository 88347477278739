import { AccountServices } from 'src/services/account-service';
import { MarginModeService } from 'src/services/margin-mode';
import { PreferenceService } from 'src/services/preference';
import {
  CLEAR_CURRENT_ACCOUNT,
  CURRENT_ACCOUNT,
  GET_MARGIN_MODE,
  GET_PREFERENCE,
  SET_ASSETS,
  SET_MARGIN_MODE,
} from '../constants/account';
import { UserSetting } from '../reducers/account';

export const clearCurrentAccount = () => {
  return {
    type: CLEAR_CURRENT_ACCOUNT,
  };
};

export const setCurrentAccount = (account: string) => {
  return {
    type: CURRENT_ACCOUNT,
    payload: { account },
  };
};

export const getMarginModeActions = (instrumentId: number) => async (dispatch: any) => {
  try {
    const instrumentsService = new MarginModeService();
    const { data: res } = await instrumentsService.getMarginMode({ instrumentId });

    dispatch({
      type: GET_MARGIN_MODE,
      payload: res,
    });
  } catch (error) {}
};

export const setMarginModeAccount = (payload: string) => {
  return {
    type: SET_MARGIN_MODE,
    payload,
  };
};

export const getPreference = () => async (dispatch: any) => {
  try {
    const preferenceService = new PreferenceService();
    const { data } = await preferenceService.getPreference();
    const dataPreference: UserSetting = {
      fundingFeeTrigger: data?.fundingFeeTrigger,
      limitOrder: data?.limitOrder,
      marketOrder: data?.marketOrder,
      stopLimitOrder: data?.stopLimitOrder,
      stopMarketOrder: data?.stopMarketOrder,
      traillingStopOrder: data?.traillingStopOrder,
      postOnlyOrder: data?.postOnlyOrder,
      takeProfitTrigger: data?.takeProfitTrigger,
      stopLossTrigger: data?.stopLossTrigger,
      fundingFeeTriggerValue: data?.fundingFeeTriggerValue,
    };
    dispatch({
      type: GET_PREFERENCE,
      payload: dataPreference,
    });
  } catch (error) {
    console.log('Error', error);
  }
};

export const getBalanceAssets = () => async (dispatch: any) => {
  try {
    const accountServices = new AccountServices();
    const { data } = await accountServices.getBalance();
    dispatch({
      type: SET_ASSETS,
      payload: data,
    });
  } catch (error) {
    console.log('Error', error);
  }
};
