import { useWeb3React } from '@web3-react/core';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { fracToast2 } from 'src/components/07.toast';
import { useLogin } from 'src/hooks/useLogin';
import { ConnectorKey, connectors } from '../connectors';
import { metaMask } from '../connectors/metmask';
import { CONNECTOR_KEY } from '../constants/storages';
import { useConnectWallet } from './useConnectWallet';

export function useEagerConnect() {
  const { isActive } = useWeb3React();
  const { disconnectWallet } = useConnectWallet();
  const { retrySocket } = useLogin();
  const history = useHistory();
  const [tried, setTried] = useState(false);

  const handleConnectMetamask = async () => {
    const unlockedFunc = (window.ethereum as any)?._metamask?.isUnlocked;
    if (typeof unlockedFunc === 'function') {
      const isUnlock = await (window.ethereum as any)?._metamask.isUnlocked();
      if (isUnlock) {
        await metaMask.connectEagerly();
      } else {
        disconnectWallet();
      }
    } else {
      await metaMask?.connectEagerly();
    }
  };

  const reconnect = async () => {
    if (!isActive) {
      const wallet = window.localStorage.getItem(CONNECTOR_KEY);
      if (
        [ConnectorKey.coinBase, ConnectorKey.walletConnect, ConnectorKey.trustWallet].includes(
          wallet as ConnectorKey,
        )
      ) {
        const connector = connectors[wallet as ConnectorKey];
        await connector?.connectEagerly();
      } else if (wallet === ConnectorKey.metaMask) {
        await handleConnectMetamask();
      }
      setTried(true);
    } else {
      setTried(true);
    }
  };

  const handleShowExpiredTokenToast = () => {
    fracToast2.error('Your session is expired');
  };

  useEffect(() => {
    if (window) {
      (window as any).disconnect = disconnectWallet;
      (window as any).retrySocket = retrySocket;
      (window as any).handleShowExpiredTokenToast = debounce(handleShowExpiredTokenToast, 500);
      (window as any).push = history.push;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
  return tried;
}
