import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './styles.scss';
import FooterPortfolio from '../portfolio/components/footer';
import { FAQS } from 'src/constants/faqs';
import FaqsCollapse from '../faqs/components/01.faqs-collapse';
import IntercomHelpCenter from 'src/components/20.intracom-help';

const HelpCenterPage: React.FC = () => {
  const { t } = useTranslation('translations');
  const userTheme = useSelector((state: any) => state.theme);

  return (
    <div className={`help-wrapper ${userTheme}`}>
      <div className="help-title">{t('commons.helpCenter')}</div>
      <div className="help-collapse-list">
        {FAQS?.map((item) => {
          return <FaqsCollapse key={item.id} question={item.question} answer={item.answer} />;
        })}
      </div>
      <div className="help-footer">
        <FooterPortfolio />
      </div>
      <IntercomHelpCenter />
    </div>
  );
};

export default HelpCenterPage;
