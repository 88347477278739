import { TradeService } from 'src/services/trade-service';
import * as trade from '../constants/trade';
import { RECORD_TRADE_LIMIT } from '../reducers/trade';

export const changeUpdateAt = (payload: number) => {
  return {
    type: trade.CHANGE_UPDATED_AT,
    payload,
  };
};

export const addNewRecentTrades = (payload: any) => {
  return {
    type: trade.ADD_NEW_TRADES,
    payload,
  };
};

export const initMarketTrades = (payload: any) => {
  return {
    type: trade.INIT_TRADES,
    payload,
  };
};

export const clearMarketTrades = () => {
  return {
    type: trade.CLEAR_MARKET_TRADES,
  };
};

export const updateIsReady = (payload: boolean) => {
  return {
    type: trade.UPDATE_IS_READY,
    payload,
  };
};

export const initMarketTradeData = (symbol: string) => async (dispatch: any) => {
  dispatch(updateIsReady(false));
  try {
    const tradeService = new TradeService();
    const response = await tradeService.getListTradeByPairSymbol(symbol, {
      page: 1,
      limit: RECORD_TRADE_LIMIT,
    });
    dispatch(initMarketTrades(response.data));
  } catch (error) {
    console.log('error', error);
  }
  dispatch(updateIsReady(true));
};
