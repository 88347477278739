import BigNumber from 'bignumber.js';
import { RootStore } from 'src/store';
import { updateOrderBook } from 'src/store/actions/order-book';
import { IOrderbook } from 'src/store/reducers/order-book';

const updateRows = (
  rows: string[][],
  newRows: string[][],
  comparator: (n1: string, n2: string) => number,
): string[][] => {
  let updatedRows: string[][] = [];

  let rowIndex = 0;
  let newRowIndex = 0;
  while (rowIndex < rows.length && newRowIndex < newRows.length) {
    const price = rows[rowIndex][0];
    const quantity = rows[rowIndex][1];
    const newPrice = newRows[newRowIndex][0];
    const newQuantity = newRows[newRowIndex][1];
    const compareResult = comparator(price, newPrice);
    if (compareResult > 0) {
      // keep old price and quantity
      updatedRows.push([price, quantity]);
      rowIndex++;
    } else if (compareResult === 0) {
      // update quantity
      if (new BigNumber(newQuantity).gt(0)) {
        updatedRows.push([price, newQuantity]);
      }
      rowIndex++;
      newRowIndex++;
    } else {
      // insert new price and quantity
      if (new BigNumber(newQuantity).gt(0)) {
        updatedRows.push([newPrice, newQuantity]);
      }
      newRowIndex++;
    }
  }

  updatedRows = updatedRows.concat(rows.slice(rowIndex));
  updatedRows = updatedRows.concat(
    newRows.slice(newRowIndex).filter((row) => new BigNumber(row[1]).gt(0)),
  );

  return updatedRows;
};

export const applyOrderbookUpdates = (orderbook: IOrderbook, orderbookUpdates: IOrderbook[]) => {
  for (const orderbookUpdate of orderbookUpdates) {
    if (orderbookUpdate.updatedAt > orderbook.updatedAt) {
      return applyOrderbookUpdate(orderbook, orderbookUpdate);
    }
  }
};

export const onReceiveUpdates = (store: RootStore, data: IOrderbook) => {
  try {
    const state = store.getState();
    const orderbook = state.orderBook as any;
    const symbol = state.instrument.currentInstrument?.symbol;
    if (data.symbol !== symbol) {
      return;
    }

    if (orderbook.isReady) {
      store.dispatch(updateOrderBook(data));

      // if (orderbook.orderbook.updatedAt === data.lastUpdatedAt) {
      //   store.dispatch(updateOrderBook(data));
      // } else {
      //   store.dispatch(clearOrderBook());
      //   store.dispatch(fetchOrderBook(symbol));
      // }
    } else {
      store.dispatch(updateOrderBook(data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const applyOrderbookUpdate = (orderbook: IOrderbook, orderbookUpdate: IOrderbook) => {
  orderbook.bids = updateRows(orderbook.bids, orderbookUpdate.bids, (n1: string, n2: string) =>
    new BigNumber(n1).comparedTo(n2),
  );
  orderbook.asks = updateRows(orderbook.asks, orderbookUpdate.asks, (n1: string, n2: string) =>
    new BigNumber(n2).comparedTo(n1),
  );
  orderbook.updatedAt = orderbookUpdate.updatedAt;
};
