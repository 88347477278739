import React from 'react';
import './styles.scss';

import AxorModal, { IFracModalProps } from '..';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { THEME_MODE } from 'src/interfaces/theme';
import ButtonContained from 'src/components/02.buttons/ButtonContained';
import useWindowSize from 'src/hooks/useWindowSize';

interface IConnectWalletModalProps extends IFracModalProps {}

const ConnectingModal: React.FC<IConnectWalletModalProps> = ({
  open,
  onCancel,
}: IConnectWalletModalProps) => {
  const { t } = useTranslation('translations');
  const { isMobile } = useWindowSize();
  const userTheme = useSelector((state: RootState) => state.theme);

  return (
    <AxorModal
      open={open}
      width={725}
      wrapClassName={`connecting-modal ${
        userTheme === THEME_MODE.DARK ? 'connecting-modal--dark' : 'connecting-modal--light'
      }`}
    >
      <div className="connecting-modal__container">
        <div className="icon">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: isMobile ? 32 : 40, color: '#4827FE' }} spin />
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <p className="title">{t('commons.connecting')}</p>
          <p className="description">
            Check your wallet for a connection request. Connecting to Axor Exchange is free and does
            not affect your funds
          </p>
        </div>
        <ButtonContained onClick={onCancel} className="try-again-btn">
          Try again
        </ButtonContained>
      </div>
    </AxorModal>
  );
};

export default ConnectingModal;
