export const DOMAIN_IAO = process.env.REACT_APP_DOMAIN_IAO;
export const DOMAIN_FRACTOR = process.env.REACT_APP_DOMAIN_FRACTOR;
export const DOMAIN_DEV_NIBIAA = process.env.REACT_APP_DOMAIN_DEV_NIBIAA;

export const PATHS = {
  default: () => '/',
  exchange: () => '/trade',
  notFound: () => '/404',
  restricted: () => '/restricted',
  badGateway: () => '/502',
  maintenance: () => '/under-maintenance',
  portfolio: () => `/portfolio`,
  overview: () => `/portfolio/overview`,
  pnl: () => `/portfolio/pnl`,
  // ranking: () => `/portfolio/ranking`,
  rankingAbsolute: () => `/portfolio/absolute`,
  rankingRelative: () => `/portfolio/relative`,
  rankingDaily: () => `/portfolio/daily`,
  rankingEpoch: () => `/portfolio/epoch`,
  rankingBadges: () => `/portfolio/badges`,
  openOrder: () => `/portfolio/open-order`,
  orderHistory: () => `/portfolio/order-history`,
  transactionHistory: () => `/portfolio/transaction-history`,
  tradeHistory: () => `/portfolio/trade-history`,
  myOverView: () => `/my-portfolio`,
  faqs: () => `/faqs`,
  privacy: () => `/privacy`,
  terms: () => `/terms`,
  helpCenter: () => `/help-center`
};

export const PATHS_IAO = {
  iao: () => `${DOMAIN_IAO}`,
  stake: () => `${DOMAIN_IAO}/stake`,
  unStake: () => `${DOMAIN_IAO}/unstake`,
  historyStake: () => `${DOMAIN_IAO}/stake-history`,
  affiliates: () => `${DOMAIN_IAO}/affiliates-dashboard`,
  becomeAffiliates: () => `${DOMAIN_IAO}/become-affiliate`,
  userGuides: () => `${DOMAIN_IAO}/user-guides`,
  aboutUs: () => `${DOMAIN_IAO}/contact-us`,
  viewAllNotification: () => `${DOMAIN_IAO}/notifications`,
  viewAll: () => `${DOMAIN_IAO}/iao-event`,
  myProfile: () => `${DOMAIN_IAO}/my-profile`,
  myIAOEvent: () => `${DOMAIN_IAO}/my-iao-event`,
  myFNFT: () => `${DOMAIN_IAO}/my-fnft`,
  myNFTs: () => `${DOMAIN_IAO}/my-nfts`,
  assetRedemption: () => `${DOMAIN_IAO}/asset-redemption`,
  settings: () => `${DOMAIN_IAO}/settings`,
  viewSpec: () => `${DOMAIN_FRACTOR}/collection`,
  trackAssetNow: () => `${DOMAIN_DEV_NIBIAA}/dashboard`,
  withdrawRequest: () => `${DOMAIN_IAO}/withdraw-request`,
};
