import {
  GET_COIN_INFO,
  GET_FUNDING_RATE_HISTORY,
  GET_LEVERAGE_MARGIN,
  GET_TRADING_RULE,
  SET_IS_OPEN_MODAL,
} from '../constants/general';
export type ITradingRule = {
  id: number;
  instrumentId: number;
  minTradeAmount: string;
  minOrderAmount: string;
  maxOrderAmount: string;
  minPrice: string;
  maxPrice?: string;
  limitOrderPrice: string;
  floorRatio: string;
  maxMarketOrder: string;
  limitOrderAmount: string;
  numberOpenOrders: string;
  priceProtectionThreshold: string;
  liqClearanceFee: string;
  minNotional: string;
  maxNotinal?: string;
  minPriceMovement?: string;
  marketOrderPrice: string;
  isReduceOnly: boolean;
  positionsNotional: string;
  ratioOfPostion: string;
  liqMarkPrice: string;
  maxLeverage: string;
  createdAt: string;
  updatedAt: string;
  multiplier?: string;
};

export interface ILeverageMargin {
  instrumentId: string;
  maintenanceAmount: string;
  maintenanceMarginRate: number;
  max: string;
  min: string;
  maxLeverage: number;
  tier: number;
}

export type ICoinInfo = {
  id: number;
  fullName: string;
  baseId: string;
  symbol: string;
  rank: string;
  marketCap: string;
  cirSupply: string;
  maxSupply: string;
  totalSupply: string;
  issueDate: null;
  issuePrice: null;
  explorer: string;
  createdAt: string;
  updatedAt: string;
  coin_image: string;
};

export type IFundingRateHistory = {
  id: number;
  time: string;
  fundingQuantity: string;
  fundingRate: string;
  fundingInterval: string;
  amount: string;
  symbol: string;
};

export type IFundingRateHistoryChart = {
  id: number;
  time: string;
  fundingQuantity: string;
  fundingRate: string;
  fundingInterval: string;
  amount: string;
  symbol: string;
};
const initialState = {
  tradingRule: {} as ITradingRule | undefined,
  loading: false,
  leverageMargin: [] as ILeverageMargin[],
  coinInfo: {} as ICoinInfo | undefined,
  fundingRateHistory: [] as IFundingRateHistory[],
  fundingRateHistoryChart: [] as IFundingRateHistoryChart[],
  isModalOpen: false,
};

export const generalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    //trading-rule
    case `${GET_TRADING_RULE}_pending`:
      return { ...state, loading: true };
    case `${GET_TRADING_RULE}_fulfilled`:
      return { ...state, loading: false, tradingRule: action.payload };

    //coin-info
    case `${GET_COIN_INFO}_pending`:
      return { ...state, loading: true };
    case `${GET_COIN_INFO}_fulfilled`:
      return { ...state, loading: false, coinInfo: action.payload };

    //funding-rate-history
    case `${GET_FUNDING_RATE_HISTORY}_pending`:
      return { ...state, loading: true };
    case `${GET_FUNDING_RATE_HISTORY}_fulfilled`:
      return { ...state, loading: false, fundingRateHistory: action.payload };

    //leverage-margin
    case `${GET_LEVERAGE_MARGIN}_pending`:
      return { ...state, loading: true };
    case `${GET_LEVERAGE_MARGIN}_fulfilled`:
      return { ...state, loading: false, leverageMargin: action.payload };

    //isOpenModal
    case SET_IS_OPEN_MODAL:
      return { ...state, isModalOpen: action.payload };

    default:
      return state;
  }
};

export default generalReducer;
