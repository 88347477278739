import { useDispatch } from 'react-redux';
import { removeStorageJwtToken } from 'src/helpers/storage';
import { clearCurrentAccount } from 'src/store/actions/account';
import { CONNECTOR_KEY } from 'src/web3/constants/storages';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_CHAIN_ID,
  REACT_APP_CHAIN_ID_HEX,
  REACT_APP_NETWORK_NAME,
  REACT_APP_RPC_URL,
} from '../constants/envs';
import { useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { useDisconnect } from 'wagmi';
import { ConnectorKey, connectors } from '../connectors';

/**
 * Hook for connect/disconnect to a wallet
 * @returns `connectWallet` and `disconnectWallet` functions .
 */
export type Network = {
  chainId: number;
  chainIdHex: string;
  rpcUrls: string[];
  chainName: string;
  nativeCurrency: { name: string; decimals: number; symbol: string };
  blockExplorerUrls: string[];
};

export const SUPPORTED_NETWORKS: { [key: string]: Network } = {
  [REACT_APP_CHAIN_ID as string]: {
    chainId: Number(REACT_APP_CHAIN_ID),
    chainIdHex: REACT_APP_CHAIN_ID_HEX as string,
    chainName: REACT_APP_NETWORK_NAME as string,
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    blockExplorerUrls: [REACT_APP_BLOCK_EXPLORER_URL as string],
    rpcUrls: [REACT_APP_RPC_URL as string],
  },
};

export const useConnectWallet = () => {
  const { reset, disconnect, disconnectAsync } = useDisconnect();
  const dispatch = useDispatch();
  const history = useHistory();

  async function connectWallet(connectorKey: ConnectorKey) {
    const connector = connectors[connectorKey];
    const chainId = parseInt(REACT_APP_CHAIN_ID as string, 10) as keyof typeof SUPPORTED_NETWORKS;
    try {
      const objAddNetWork =
        connectorKey === ConnectorKey.metaMask
          ? ({
              chainId: chainId,
              chainName: SUPPORTED_NETWORKS[chainId].chainName,
              nativeCurrency: {
                name: 'BNB',
                symbol: 'bnb',
                decimals: 18,
              },
              rpcUrls: [REACT_APP_RPC_URL],
              blockExplorerUrls: SUPPORTED_NETWORKS[chainId].blockExplorerUrls,
            } as any)
          : undefined;
      await connector.activate(objAddNetWork);
    } catch (error: any) {
      throw error;
    }
  }

  async function disconnectWallet(backToDefaultPage = true) {
    removeStorageJwtToken();
    removeStorageWallet();
    dispatch(clearCurrentAccount());
    reset();
    disconnect();
    disconnectAsync();
    if (backToDefaultPage) {
      history.push(PATHS.default());
    }
  }

  const resetStorage = () => {
    removeStorageJwtToken();
    removeStorageWallet();
    dispatch(clearCurrentAccount());
  };

  return { disconnectWallet, resetStorage, connectWallet };
};

function removeStorageWallet() {
  window.localStorage.removeItem(CONNECTOR_KEY);
}
