export enum OrderSide {
  Buy = 1,
  Sell = 2,
}

export enum OrderSideFuture {
  buy = 'BUY',
  sell = 'SELL',
}

export enum OrderType {
  Limit = 1,
  Market = 2,
}

export enum WorkingTypeValue {
  last = 'LAST',
  mark = 'ORACLE',
}

export const WorkingType = [
  { value: WorkingTypeValue.mark, label: 'orders.mark', key: 'mark' },
  { value: WorkingTypeValue.last, label: 'orders.last', key: 'last' },
];

export const TIME_IN_FORCE = [
  { value: 'GTC', label: 'GTC' },
  { value: 'IOC', label: 'IOC' },
  { value: 'FOK', label: 'FOK' },
];

export enum OrderTypeFuture {
  all = '',
  market = 'MARKET',
  limit = 'LIMIT',
  stopMarket = 'STOP_MARKET',
  stopLossMarket = 'STOP_LOSS_MARKET',
  stopLimit = 'STOP_LIMIT',
  stopLoss = 'STOP_LOSS',
  trailingStop = 'TRAILING_STOP',
  postOnly = 'POST_ONLY',
  takeProfitLimit = 'TAKE_PROFIT_LIMIT',
  takeProfitMarket = 'TAKE_PROFIT_MARKET',
  takeProfit = 'TAKE_PROFIT',
  liquidation = 'LIQUIDATION',
}

export const renderTypeOrder = (type: string) => {
  switch (type) {
    case OrderTypeFuture.limit:
      return 'orders.limit';
    case OrderTypeFuture.market:
      return 'orders.market';
    case OrderTypeFuture.takeProfitLimit:
      return 'orders.take_profit';
    case OrderTypeFuture.takeProfitMarket:
      return 'orders.take_profit';
    case OrderTypeFuture.takeProfit:
      return 'orders.take_profit';
    case OrderTypeFuture.postOnly:
      return 'orders.limit';
    case OrderTypeFuture.stopLimit:
      return 'orders.stop_limit';
    case OrderTypeFuture.stopMarket:
      return 'orders.stop_market';
    case OrderTypeFuture.stopLoss:
      return 'orders.stop_loss';
    case OrderTypeFuture.trailingStop:
      return 'orders.trailing_stop';
    case OrderTypeFuture.liquidation:
      return 'orders.liquidation';
    default:
      return '--';
  }
};

export enum TransactionTypeFuture {
  all = '',
  transfer = 'TRANSFER',
  realizedPnl = 'REALIZED_PNL',
  fundingFee = 'FUNDING_FEE',
  tradingFee = 'TRADING_FEE',
  liquidationClearance = 'LIQUIDATION_CLEARANCE',
  // deposit = 'DEPOSIT',
  // withdrawal = 'WITHDRAWAL',
  // trade = 'TRADE',
  // referral = 'REFERRAL',
  // reward = 'REWARD',
}
export enum TransactionTypeLabel {
  all = 'portfolios.all',
  transfer = 'portfolios.transfer',
  fundingFee = 'portfolios.funding_fee',
  tradingFee = 'portfolios.trading_fee',
  realizedPnl = 'portfolios.realized_pnl',
  liquidationClearance = 'portfolios.liquidation_clearance',
  // deposit = 'portfolios.deposit',
  // withdrawal = 'portfolios.withdraw',
  // trade = 'portfolios.trade',
  // referral = 'portfolios.referral',
  // reward = 'portfolios.reward',
}

export enum OrderOption {
  none,
  tpsl,
  reduceOnly,
}

export enum StopLabel {
  all = 'orders.all',
  limit = 'orders.limit',
  market = 'orders.market',
  stopMarket = 'orders.stop_market',
  stopLimit = 'orders.stop_limit',
  trailingStop = 'orders.trailing_stop',
  postOnly = 'orders.post_only',
  takeProfitLimit = 'orders.take_profit',
  stopLoss = 'orders.stop_loss',
  takeProfit = 'orders.take_profit',
  liquidation = 'orders.liquidation',
  // takeProfitMarket = 'Take Profit Market',
}

export const STOP_TYPE = [
  { value: null, label: StopLabel.all, key: 'all' },
  { value: OrderTypeFuture.limit, label: StopLabel.limit, key: 'limit' },
  { value: OrderTypeFuture.market, label: StopLabel.market, key: 'market' },
  { value: OrderTypeFuture.stopLimit, label: StopLabel.stopLimit, key: 'stop_limit' },
  { value: OrderTypeFuture.stopMarket, label: StopLabel.stopMarket, key: 'stop_market' },
  { value: OrderTypeFuture.trailingStop, label: StopLabel.trailingStop, key: 'trailing_stop' },
  // { value: OrderTypeFuture.postOnly, label: StopLabel.postOnly, key: 'post_only' },
  { value: OrderTypeFuture.takeProfitMarket, label: StopLabel.takeProfit, key: 'take_profit' },
  { value: OrderTypeFuture.stopLossMarket, label: StopLabel.stopLoss, key: 'stop_loss' },
  { value: OrderTypeFuture.liquidation, label: StopLabel.liquidation, key: 'liquidation' },
  // { value: OrderTypeFuture.takeProfitMarket, label: StopLabel.takeProfitMarket },
];

export const STOP_TYPE_CREATE_ORDER = [
  { value: OrderTypeFuture.stopLimit, label: StopLabel.stopLimit, key: 'stop_limit' },
  { value: OrderTypeFuture.stopMarket, label: StopLabel.stopMarket, key: 'stop_market' },
  { value: OrderTypeFuture.trailingStop, label: StopLabel.trailingStop, key: 'trailing_stop' },
  { value: OrderTypeFuture.postOnly, label: StopLabel.postOnly, key: 'post_only' },
];

export const TRANSACTION_TYPES = [
  { value: null, label: TransactionTypeLabel.all, key: 'all' },
  // { value: TransactionTypeFuture.transfer, label: TransactionTypeLabel.transfer, key: 'transfer' },
  {
    value: TransactionTypeFuture.realizedPnl,
    label: TransactionTypeLabel.realizedPnl,
    key: 'realized_pnl',
  },
  {
    value: TransactionTypeFuture.fundingFee,
    label: TransactionTypeLabel.fundingFee,
    key: 'funding_fee',
  },
  {
    value: TransactionTypeFuture.tradingFee,
    label: TransactionTypeLabel.tradingFee,
    key: 'trading_fee',
  },
];

export enum ORDER_STATUS {
  Invalid = -2,
  Pending = 0, // Pending order waiting for lock balance
  Canceled = -1, // Cancel order
  Fillable = 1, // Order waiting for exchange
  Filling = 2, // Order in exchange processing with another order
  Fulfill = 3, // Order is done
  PartiallyFilled = 4,
}

export enum TradingMethod {
  StellarOrderbook = 1,
  BSCOrderbook = 2,
  BSCPool = 4,
  PancakeswapPool = 8,
  CombinedOrderbook = TradingMethod.StellarOrderbook | TradingMethod.BSCOrderbook,
  All = TradingMethod.StellarOrderbook | TradingMethod.BSCOrderbook | TradingMethod.BSCPool,
}

export const BOOLEAN = {
  TRUE: 1,
  FALSE: 0,
};

export const LOCAL_STORAGE_FAVORITES_KEY: string = 'favorite_pairs';

export enum OrderBookLength {
  type1 = 8,
  type2 = 30,
}

export const DECIMAL_COIN = {
  STABLE: 4,
  FNFT: 3,
};

export const IS_STABLE_COIN = 0;

