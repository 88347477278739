import { t } from 'i18next';
import {
  OverviewIcon,
  PNLIcon,
  RankingIcon,
  TransactionHistoryIcon,
} from 'src/assets/icons/IconComponent';

export const MENUS_KEY = {
  OVER_VIEW: 'portfolio/overview',
  TRANSACTION_HISTORY: 'portfolio/transaction-history',
  PNL: 'portfolio/pnl',
  RANKING: 'portfolio/ranking',
};

export const MENU_ITEMS = {
  OVER_VIEW: {
    key: 'portfolio/overview',
    label: t('portfolios:portfolios.overview'),
    level: 1,
    icon: (props?: any) => <OverviewIcon color={props?.color} />,
  },
  TRANSACTION_HISTORY: {
    key: 'transaction',
    label: t('portfolios:portfolios.transaction_history'),
    lever: 1,
    icon: (props?: any) => <TransactionHistoryIcon color={props?.color} />,
    children: [
      {
        key: 'portfolio/open-order',
        label: t('portfolios:portfolios.open_order'),
        path: 'portfolio/open-order',
        level: 2,
      },
      {
        key: 'portfolio/order-history',
        label: t('portfolios:portfolios.order_history'),
        path: 'portfolio/order-history',
        level: 2,
      },
      {
        key: 'portfolio/trade-history',
        label: t('portfolios:portfolios.trade_history'),
        path: 'portfolio/trade-history',
        level: 2,
      },
      {
        key: 'portfolio/transaction-history',
        label: t('portfolios:portfolios.transaction_history'),
        path: 'portfolio/transaction-history',
        level: 2,
      },
    ],
  },
  PNL: {
    key: 'portfolio/pnl',
    label: t('portfolios:portfolios.pnl'),
    level: 1,
    icon: (props?: any) => <PNLIcon color={props?.color} />,
  },
  RANKING: {
    key: 'portfolio/ranking ',
    label: t('portfolios:portfolios.ranking'),
    lever: 1,
    icon: (props?: any) => <RankingIcon color={props?.color} />,
    children: [
      {
        key: 'portfolio/absolute',
        label: t('portfolios:portfolios.absolute_pl'),
        path: 'portfolio/absolute',
        level: 2,
      },
      {
        key: 'portfolio/relative',
        label: t('portfolios:portfolios.relative_pl'),
        path: 'portfolio/relative',
        level: 2,
      },
      {
        key: 'portfolio/daily',
        label: t('portfolios:portfolios.daily_ranking'),
        path: 'portfolio/daily',
        level: 2,
      },
      {
        key: 'portfolio/epoch',
        label: t('portfolios:portfolios.epoch_ranking'),
        path: 'portfolio/epoch',
        level: 2,
      },
      {
        key: 'portfolio/badges',
        label: t('portfolios:portfolios.badges'),
        path: 'portfolio/badges',
        level: 2,
      },
    ],
  },
};
