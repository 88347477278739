import { combineReducers } from 'redux';
import { balancesReducer } from './reducers/balances';
import { feeReducer } from './reducers/fee';
import generalReducer from './reducers/general';
import instrumentReducer from './reducers/instrument';
import { masterdataFutureReducer } from './reducers/masterdataFuture';
import { networkReducer } from './reducers/network';
import { orderBookReducer } from './reducers/order-book';
import { orderReducer } from './reducers/orders';
import { pairReducer } from './reducers/pairs';
import positionsReducer from './reducers/positions';
import { themeReducer } from './reducers/theme';
import tickerReducer from './reducers/ticker';
import { tradeReducer } from './reducers/trade';
import { userReducer } from './reducers/user';
import { walletAnalyticReducer } from './reducers/wallet-analytic';
import accountReducer from './reducers/account';

const appReducer = combineReducers({
  networkIsWrong: networkReducer,
  currentAccount: accountReducer,
  pairs: pairReducer,
  orderBook: orderBookReducer,
  balances: balancesReducer,
  walletAnalytic: walletAnalyticReducer,
  fee: feeReducer,
  user: userReducer,
  theme: themeReducer,
  trade: tradeReducer,
  masterdataFuture: masterdataFutureReducer,
  instrument: instrumentReducer,
  general: generalReducer,
  ticker: tickerReducer,
  positions: positionsReducer,
  order: orderReducer,
});

export const rootReducer = (state: any, action: any) => appReducer(state, action);
