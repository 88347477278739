import { Instrument } from 'src/interfaces/instrument';
import {
  GET_FAV_INSTRUMENT,
  GET_INSTRUMENT,
  SET_CURRENT_INSTRUMENT,
  UPDATE_FAV_INSTRUMENT,
} from '../constants/instrument';

const initialInstrument = {
  id: 1,
  symbol: '',
  contractAddress: '',
  rootSymbol: '',
  state: '',
  type: 0,
  expiry: '',
  baseUnderlying: '',
  quoteCurrency: '',
  underlyingSymbol: '',
  settleCurrency: '',
  initMargin: '',
  maintainMargin: '',
  deleverageable: true,
  makerFee: '',
  takerFee: '',
  settlementFee: '',
  hasLiquidity: false,
  referenceIndex: '',
  settlementIndex: '',
  fundingBaseIndex: '',
  fundingQuoteIndex: '',
  fundingPremiumIndex: '',
  fundingInterval: 0,
  tickSize: '',
  contractSize: '',
  lotSize: '',
  maxPrice: '',
  maxOrderQty: 0,
  multiplier: '',
  optionStrikePrice: '',
  optionKoPrice: '',
  riskLimit: '',
  riskStep: '',
  rank: 0,
  createdAt: '',
  updatedAt: '',
  tokenDecimals: 18,
  orderAddress: '',
};

const initialState: {
  instrument: Instrument[];
  currentInstrument: Instrument;
  favoriteInstruments: { symbol: string }[];
  loading: boolean;
} = {
  instrument: [],
  currentInstrument: initialInstrument,
  favoriteInstruments: [],
  loading: false,
};

export const instrumentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `${GET_INSTRUMENT}_pending`:
      return { ...state, loading: true };

    case `${GET_INSTRUMENT}_rejected`:
      return { ...state, loading: false };

    case `${GET_INSTRUMENT}_fulfilled`:
      let currentInstrument;
      if (!state.currentInstrument?.symbol) {
        currentInstrument = action.payload[0];
      } else {
        currentInstrument = state.currentInstrument;
      }
      return {
        ...state,
        loading: false,
        instrument: action.payload,
        currentInstrument,
      };
    case SET_CURRENT_INSTRUMENT:
      return {
        ...state,
        currentInstrument: action.payload,
      };
    case GET_FAV_INSTRUMENT:
      return { ...state, favoriteInstruments: action.payload };

    case UPDATE_FAV_INSTRUMENT:
      return { ...state, favoriteInstruments: action.payload };

    default:
      return state;
  }
};

export default instrumentReducer;
