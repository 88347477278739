import { applyOrderbookUpdate, applyOrderbookUpdates } from 'src/helpers/orderBooks';
import { INIT_ORDER_BOOK, UPDATE_ORDER_BOOK } from 'src/store/constants/order-book';
import { CLEAR_ORDER_BOOK, PENDING_ORDER_BOOK } from './../constants/order-book';

// const INIT_VALUE = new BigNumber(0);

export interface IOrderbook {
  bids: string[][];
  asks: string[][];
  updatedAt: number;
  lastUpdatedAt?: number;
  symbol?: string;
}

export interface OrderBookStates {
  orderbook: IOrderbook;
  isReady: boolean;
  pendingUpdates: IOrderbook[];
  selectedOrder: any;
}

const initialState: OrderBookStates = {
  orderbook: {
    bids: [],
    asks: [],
    updatedAt: Date.now(),
  },
  isReady: false,
  pendingUpdates: [],
  selectedOrder: null,
};

export const orderBookReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INIT_ORDER_BOOK:
      const orderbook = action.payload;
      applyOrderbookUpdates(orderbook, state.pendingUpdates);
      return {
        ...state,
        orderbook: {
          ...state.orderbook,
          ...orderbook,
        },
        isReady: true,
        pendingUpdates: [],
      };

    case UPDATE_ORDER_BOOK:
      if (state.isReady) {
        const orderbook = { ...state.orderbook } as IOrderbook;

        applyOrderbookUpdate(orderbook, action.payload);
        state.orderbook = orderbook;
      } else {
        state.pendingUpdates = [...state.pendingUpdates, action.payload];
      }

      return {
        ...state
      };

    case CLEAR_ORDER_BOOK:
      return initialState;

    case PENDING_ORDER_BOOK:
      return {
        ...state,
        isReady: !action.payload,
      };

    default:
      return state;
  }
};
