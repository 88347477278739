import { Dispatch } from 'redux';
import { MasterDataService } from 'src/services/master-data-service';
import {
  MASTER_DATA_FUTURE_FULFILLED,
  MASTER_DATA_FUTURE_PENDING,
  MASTER_DATA_FUTURE_REJECTED,
} from '../constants/masterdataFuture';

export const getMasterDataFuture = () => async (dispatch: Dispatch) => {
  dispatch({ type: MASTER_DATA_FUTURE_PENDING });
  try {
    const masterDataService = new MasterDataService();
    const { data } = await masterDataService.getMasterData();
    dispatch({
      type: MASTER_DATA_FUTURE_FULFILLED,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: MASTER_DATA_FUTURE_REJECTED,
      error: error.message,
    });
  }
};
