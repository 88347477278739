const MenuIcon = ({ color, isOpen }: any) => {
  // return !isOpen ? (
  //   <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
  //     <rect x="2" y="7" width="20" height="2" rx="1" fill={color} />
  //     <rect x="2" y="15" width="20" height="2" rx="1" fill={color} />
  //   </svg>
  // ) : (
  //   <svg width="18" height="18" viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       d="M0.968387 0.97034C1.03806 0.900495 1.12082 0.845081 1.21194 0.807271C1.30305 0.769462 1.40074 0.75 1.49939 0.75C1.59804 0.75 1.69572 0.769462 1.78684 0.807271C1.87796 0.845081 1.96072 0.900495 2.03039 0.97034L5.99939 4.94084L9.96839 0.97034C10.0381 0.900608 10.1209 0.845294 10.212 0.807555C10.3031 0.769817 10.4008 0.750393 10.4994 0.750393C10.598 0.750393 10.6957 0.769817 10.7868 0.807555C10.8779 0.845294 10.9607 0.900608 11.0304 0.97034C11.1001 1.04007 11.1554 1.12286 11.1932 1.21396C11.2309 1.30507 11.2503 1.40272 11.2503 1.50134C11.2503 1.59996 11.2309 1.69761 11.1932 1.78872C11.1554 1.87983 11.1001 1.96261 11.0304 2.03234L7.05989 6.00134L11.0304 9.97034C11.1001 10.0401 11.1554 10.1229 11.1932 10.214C11.2309 10.3051 11.2503 10.4027 11.2503 10.5013C11.2503 10.6 11.2309 10.6976 11.1932 10.7887C11.1554 10.8798 11.1001 10.9626 11.0304 11.0323C10.9607 11.1021 10.8779 11.1574 10.7868 11.1951C10.6957 11.2329 10.598 11.2523 10.4994 11.2523C10.4008 11.2523 10.3031 11.2329 10.212 11.1951C10.1209 11.1574 10.0381 11.1021 9.96839 11.0323L5.99939 7.06184L2.03039 11.0323C1.96066 11.1021 1.87787 11.1574 1.78676 11.1951C1.69565 11.2329 1.598 11.2523 1.49939 11.2523C1.40077 11.2523 1.30312 11.2329 1.21201 11.1951C1.1209 11.1574 1.03812 11.1021 0.968387 11.0323C0.898655 10.9626 0.843341 10.8798 0.805602 10.7887C0.767863 10.6976 0.74844 10.6 0.74844 10.5013C0.74844 10.4027 0.767863 10.3051 0.805602 10.214C0.843341 10.1229 0.898655 10.0401 0.968387 9.97034L4.93889 6.00134L0.968387 2.03234C0.898542 1.96267 0.843128 1.87991 0.805318 1.78879C0.767508 1.69767 0.748047 1.59999 0.748047 1.50134C0.748047 1.40269 0.767508 1.30501 0.805318 1.21389C0.843128 1.12277 0.898542 1.04001 0.968387 0.97034Z"
  //       fill={color}
  //     />
  //   </svg>
  // );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M15 18H3V16H15V18ZM21 13H3V11H21V13ZM15 8H3V6H15V8Z" fill={color} />
    </svg>
  );
};

export default MenuIcon;
