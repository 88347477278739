import { IOrder } from 'src/types/orders';
import {
  ADD_ALL_ORDERS,
  CLEAR_ALL_ORDERS,
  GET_ORDERS,
  GET_ORDER_ERROR,
  UPDATE_ALL_ORDERS,
  UPDATE_LOADING,
  UPDATE_ORDERS,
} from '../constants/orders';

export type OpenOrderStates = {
  allOrders: IOrder[];
  orders: IOrder[];
  loading: boolean;
  firstLoad: boolean;
  totalPage: number;
  totalItem: number;
  currentPage: number;
  currentSymbol: string;
  error: string;
};

export enum OrderStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FILLED = 'FILLED',
  CANCELED = 'CANCELED',
  UNTRIGGERED = 'UNTRIGGERED',
}

const initialState: OpenOrderStates = {
  allOrders: [],
  orders: [],
  loading: false,
  firstLoad: true,
  totalPage: 0,
  totalItem: 0,
  currentPage: 1,
  currentSymbol: '',
  error: '',
};

export const orderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
      };
    case CLEAR_ALL_ORDERS:
      return {
        ...state,
        allOrders: [],
      };
    case UPDATE_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
      };
    case UPDATE_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
        totalItem: action.payload.newTotalItem,
        totalPage: action.payload.newTotalPage,
      };
    case GET_ORDERS:
      return {
        ...state,
        firstLoad: false,
        orders: action.payload.orders,
        totalItem: action.payload.totalItem,
        totalPage: action.payload.totalPage,
        currentPage: action.payload.currentPage,
        currentSymbol: action.payload.symbol,
      };
    case UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
