import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OrderService } from 'src/services/order-service';
import { walletAnalytic } from '../constants/wallet-analytic';

export const setBalanceAll = (payload: any) => {
  return {
    type: walletAnalytic.SET_BALANCE_ALL,
    payload,
  };
};

export const setExchangeRate = (payload: any) => {
  return {
    type: walletAnalytic.SET_EXCHANGE_RATE,
    payload,
  };
};

export const setBalanceInOrder = (payload: any) => {
  return {
    type: walletAnalytic.SET_BALANCE_IN_ORDER,
    payload,
  };
};

export const setBalanceAvailable = (payload: any) => {
  return {
    type: walletAnalytic.SET_BALANCE_AVAILABLE,
    payload,
  };
};

export const getExchangeRate = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const controller = new OrderService();
      const { data } = await controller.getExchangeRate();
      dispatch(setExchangeRate(data));
    } catch (err) {
      console.error(err);
      dispatch(setExchangeRate([]));
    }
  };
};
