export const FAQS = [
        {
                id: 1,
                question: 'What is Axor?',
                answer: 'Axor is a decentralized perpetual trading protocol on the Arbitrum blockchain.',
        },
        {
                id: 2,
                question: 'Who operates Axor?',
                answer: 'Axor is operated by the Axor DAO, consisting of AXR token holders.',
        },
        {
                id: 3,
                question: 'What blockchain does Axor use?',
                answer: 'Axor operates on the Arbitrum network.',
        },
        {
                id: 4,
                question: 'What are perpetual derivatives on Axor?',
                answer:
                        'These are instruments that closely track the price movements of an underlying asset, without expiration dates.',
        },
        {
                id: 5,
                question: `What are the benefits of Axor's decentralized nature?`,
                answer: 'It enhances security, transparency, and gives traders full custody of their assets.',
        },
        {
                id: 6,
                question: 'What kind of trading does Axor support?',
                answer: 'Axor supports the decentralized trading of perpetual derivatives.',
        },
        {
                id: 7,
                question: 'What are perpetual derivatives?',
                answer: 'They are instruments that closely track the price movements of an underlying asset.',
        },
        {
                id: 8,
                question: 'Do perpetual derivatives have an expiration date?',
                answer:
                        'Unlike traditional futures contracts, these derivatives do not have an expiration date.',
        },
        {
                id: 9,
                question: 'How does Axor address centralization risks?',
                answer:
                        'By leveraging decentralized technology, Axor minimizes risks associated with centralized exchanges.',
        },
        {
                id: 10,
                question: `What is Axor's solution to liquidity challenges in DeFi?`,
                answer: 'Axor uses liquidity pools, market-making incentives, and dynamic fee structures.',
        },
        {
                id: 11,
                question: 'How does Axor simplify the platform for new users?',
                answer:
                        'Axor provides user-friendly interfaces, educational resources, and a straightforward onboarding process.',
        },
        {
                id: 12,
                question: 'What is the Global Index in Axor?',
                answer: 'It is used in funding payment calculations and updates with each transaction.',
        },
        {
                id: 13,
                question: 'What functions can users perform on the Axor protocol?',
                answer: 'Users can deposit, withdraw, trade, and participate in Governance.',
        },
        {
                id: 14,
                question: 'How does Axor ensure fair trading?',
                answer:
                        'The margin percentage of each account is checked at the end of each batch of trades.',
        },
        {
                id: 15,
                question: 'What are Trader Contracts in Axor?',
                answer:
                        'They are external contracts for processing and approving trades, separate from the core Perpetual smart contract.',
        },
        {
                id: 16,
                question: 'How does Axor manage its treasury?',
                answer:
                        'Treasury management is part of the governance framework, subject to community votes.',
        },
        {
                id: 17,
                question: 'What oracle service does Axor use?',
                answer: `For the launch, we're using Chainlink's oracle network to power secure price feeds for Layer 2. Chainlink has integrated with Arbitrum to extend the Chainlink Network's hyper-reliable oracle functions to high-performance Layer 2 solutions. In a short time, other oracle providers such as MakerDAO may be used for some markets.`,
        },
        {
                id: 18,
                question: 'What fee model does Axor use?',
                answer: 'Axor uses a maker-taker fee model.',
        },
        {
                id: 19,
                question: 'What governance powers do AXR token holders have?',
                answer: 'They can create proposals and vote on existing ones.',
        },
        {
                id: 20,
                question: `What is Axor's governance model?`,
                answer: 'Axor operates as a DAO, enabling community-driven decision-making.',
        },
        {
                id: 21,
                question: 'What restrictions does Axor impose?',
                answer:
                        'Restrictions include IP and VPN bans to maintain security and comply with regulations.',
        },
        {
                id: 22,
                question: 'Why does Axor ban VPN usage?',
                answer:
                        'To ensure compliance with regulations and prevent circumventing geographic restrictions.',
        },
        {
                id: 23,
                question: 'How does Axor integrate with existing wallets?',
                answer:
                        'Axor allows users to manage their cryptocurrency assets directly, including deposits and withdrawals.',
        },
        {
                id: 24,
                question: 'How does Axor ensure mobile compatibility?',
                answer: 'The user interface is designed to be responsive across desktop and mobile devices.',
        },
        {
                id: 25,
                question: 'What is the collateral on Axor?',
                answer: 'Collateral is held as USDT, redeemable 1:1 for USD.',
        },
        {
                id: 26,
                question: 'How does cross-margining work on Axor?',
                answer: 'It allows an account to open multiple positions sharing the same collateral.',
        },
        {
                id: 27,
                question: `What is the role of Axor's dedicated team?`,
                answer: 'The team focuses on the continuous development and improvement of the protocol.',
        },
        {
                id: 28,
                question: 'What is deleveraging in Axor?',
                answer:
                        'It is used to maintain system solvency when accounts are underwater due to rapid price changes.',
        },
        {
                id: 29,
                question: 'What happens during final settlement on Axor?',
                answer:
                        'It locks the current oracle price and Global Index, restricting all operations except special withdrawals that allow users to exit their positions.',
        },
        {
                id: 30,
                question: 'Who can trigger final settlement on Axor?',
                answer:
                        'Final settlement may be triggered by the Perpetual Admin at the end-of-life of a given Perpetual smart contract. It may also be triggered in the event that a critical vulnerability is discovered that is deemed to pose a significant risk to user funds.',
        },
        {
                id: 31,
                question: 'What can users customize in the Axor dashboard?',
                answer: 'Users can customize data points and metrics relevant to their portfolio.',
        },
];