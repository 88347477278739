import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fracToast2 } from 'src/components/07.toast';
import { getStorageJwtToken, hasStorageJwtToken } from 'src/helpers/storage';
import { useLogin } from 'src/hooks/useLogin';
import { useConnectWallet } from 'src/web3/hooks';
import { useAccount } from 'wagmi';

type JWT_DECODE = {
  exp: number;
  iat: number;
  role: number;
  sub: number;
  wallet: string;
};

export const authContext = React.createContext<
  | {
      isAuthChecking: boolean;
      isAuth: boolean;
      isWaitForSignIn: boolean;
      signIn: (data: any) => Promise<void>;
      signOut: VoidFunction;
      wrongChain: boolean;
      setWrongChain: (val: boolean) => void;
      resetSignLoading: () => void;
    }
  | undefined
>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { isReconnecting } = useAccount();
  const { disconnectWallet } = useConnectWallet();

  const { userLogin } = useLogin();

  const { t } = useTranslation();

  const [userLogined, setUserLogined] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [wrongChain, setWrongChain] = useState<boolean>(false);

  useEffect(() => {
    handleExpireDate();
  }, []);

  function handleExpireDate() {
    const isLogined = hasStorageJwtToken();
    if (isLogined) {
      setUserLogined(true);
    } else {
      setUserLogined(false);
    }
  }

  async function signIn(data: any) {
    try {
      if (!hasStorageJwtToken()) {
        setLoading(true);
        await userLogin(data);
        setUserLogined(true);
      } else {
        const decoded = jwt_decode<JWT_DECODE>(getStorageJwtToken() as string);
        if (
          decoded &&
          data?.address &&
          data?.address.toLocaleLowerCase() !== decoded.wallet.toLocaleLowerCase()
        ) {
          setLoading(true);
          await userLogin(data);
          setUserLogined(true);
        }
      }
    } catch (error: any) {
      fracToast2.error(
        t('translations:messages.errors.failedToConnect'),
        t('translations:messages.errors.failedToConnect'),
      );
      setLoading(false);
      throw error;
    }
    setLoading(false);
  }

  const resetSignLoading = () => {
    setLoading(false);
  };

  return (
    <authContext.Provider
      value={{
        isAuthChecking: loading || isReconnecting,
        isAuth: userLogined,
        isWaitForSignIn: loading,
        signIn,
        signOut: disconnectWallet,
        setWrongChain,
        wrongChain,
        resetSignLoading,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
