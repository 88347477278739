import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  getStorageJwtRefreshToken,
  getStorageJwtToken,
  setStorageJwtToken,
} from 'src/helpers/storage';
import { AuthServices } from '../auth-service';
import { API_ENDPOINT } from 'src/constants/env';
import { blockedUserCode, maintenanceUserCode } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { isStringifyJson } from 'src/helpers';

export class HttpClient {
  axiosInstance: AxiosInstance;

  constructor() {
    const tokenAccess = getStorageJwtToken();
    let configs: AxiosRequestConfig = {
      baseURL: API_ENDPOINT,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + tokenAccess,
      },
      timeout: 5000,
      transformRequest: [
        (data, headers) => {
          if (data instanceof FormData) {
            if (headers) {
              delete headers['Content-Type'];
            }
            return data;
          }
          return JSON.stringify(data);
        },
      ],
    };

    this.axiosInstance = axios.create(configs);

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const isRefreshTokenErrorApi = error.config.url.includes('refresh-token');

        if (
          error?.response?.status === 401 &&
          !originalRequest._retry &&
          !isRefreshTokenErrorApi &&
          (getStorageJwtRefreshToken() || getStorageJwtToken())
        ) {
          originalRequest._retry = true;

          try {
            const refreshToken = getStorageJwtRefreshToken();
            if (refreshToken) {
              const authService = new AuthServices();
              const response = await authService.refreshToken({
                refreshToken: refreshToken || '',
              });

              const accessToken = response?.data?.accessToken;
              this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
              originalRequest.headers['Authorization'] = accessToken;

              setStorageJwtToken(accessToken);
              setTimeout(() => {
                (window as any).retrySocket(accessToken);
              }, 100);
              //this checking is because previous data request is converted to string and need to check and convert to Object before passing to new request
              const isInvalidJsonData = isStringifyJson(originalRequest?.data);
              if (isInvalidJsonData) {
                return this.axiosInstance({
                  ...originalRequest,
                  data: JSON.parse(originalRequest.data),
                });
              }
              return this.axiosInstance(originalRequest);
            } else {
              setTimeout(() => {
                (window as any).disconnect();
                (window as any).handleShowExpiredTokenToast &&
                  (window as any).handleShowExpiredTokenToast();
              }, 500);
            }
          } catch (error) {
            setTimeout(() => {
              if ((window as any).disconnect) {
                (window as any).disconnect();
                (window as any).handleShowExpiredTokenToast &&
                  (window as any).handleShowExpiredTokenToast();
              }
            }, 500);

            return Promise.reject(error);
          }
        }

        if (
          error?.response?.status === 400 &&
          blockedUserCode.includes(error?.response?.data?.code)
        ) {
          (window as any).disconnect(false);
          (window as any).push(PATHS.restricted());
        }
        if (
          error?.response?.status === 400 &&
          maintenanceUserCode.includes(error?.response?.data?.code)
        ) {
          (window as any).disconnect(false);
          (window as any).push(PATHS.maintenance());
        }
        if (
          error?.response?.status === 502
        ) {
          (window as any).disconnect(false);
          (window as any).push(PATHS.badGateway());
        }

        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.request.use(
      (config) => {
        const accessToken = getStorageJwtToken();
        if (accessToken && config.headers) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
}
