import Icon from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ArrowLeftLanguageIconComponent,
  AssetRedemptionIconComponent,
  MyFNFTSIconComponent,
  MyIAOHistoryIconComponent,
  MyNFTSIconComponent,
  ProfileIconVer2Component,
  SettingIconVer2Component,
  SignOutIconComponent,
} from 'src/assets/icons/IconComponent';
import DisconnectWalletModal from 'src/components/06.modals/DisconnectWalletModal';
import { PATHS, PATHS_IAO } from 'src/constants/paths';
import { clearAllBalances } from 'src/store/actions/balances';
import { useConnectWallet } from 'src/web3/hooks';
import './mobile-menu-detail-content.scss';

export interface IWalletDetailContent {
  onClose: () => void;
  onDisconnect: () => void;
}

const AccountContent: React.FC<IWalletDetailContent> = ({ onClose, onDisconnect }) => {
  const { t } = useTranslation();
  const { disconnectWallet } = useConnectWallet();
  const dispatch = useDispatch();
  const history = useHistory();

  const userTheme = useSelector((state: any) => state.theme);

  const [isOpenDisconnect, setIsOpenDisconnect] = useState<boolean>(false);

  const handleDisconnect = () => {
    console.log('disconnected6');
    disconnectWallet();
    dispatch(clearAllBalances());
    onDisconnect();
    // dispatch(setStoreInfoUserIAO(''));
    // dispatch(setRoleAffiliateIAO(''));
    // setRoleAffiliate('');
    setIsOpenDisconnect(false);
    history.push(PATHS.exchange());
  };

  const items = [
    {
      className: 'pb-0',
      icon: MyIAOHistoryIconComponent,
      label: t('layout.header.profileDetail.myIAOHistory'),
      path: PATHS_IAO.myIAOEvent(),
    },
    {
      className: 'pb-0',
      icon: MyFNFTSIconComponent,
      label: t('layout.header.profileDetail.myFNFTs'),
      path: PATHS_IAO.myFNFT(),
    },
    {
      className: 'pb-0',
      icon: MyNFTSIconComponent,
      label: t('layout.header.profileDetail.myNFTs'),
      path: PATHS_IAO.myNFTs(),
    },
    {
      className: '',
      icon: AssetRedemptionIconComponent,
      label: t('layout.header.profileDetail.assetRedemption'),
      path: PATHS_IAO.assetRedemption(),
    },
  ];

  const renderContentWithAuth = () => {
    return (
      <div className={`mobile-menu-detail ${userTheme}`}>
        <div className="mobile-menu-detail__item-wrapper default-item pt-20">
          <div
            className="mobile-menu-detail__item-wrapper__item justify-flex-start"
            onClick={onClose}
          >
            <Icon className="back-icon" component={ArrowLeftLanguageIconComponent} />
            <span>{t('mobile.menu.account')}</span>
          </div>
        </div>

        <div className="mobile-menu-detail__item-wrapper">
          <a
            className="mobile-menu-detail__item-wrapper__item default-item justify-flex-start"
            href={PATHS_IAO.myProfile()}
          >
            <Icon className="item-icon" component={ProfileIconVer2Component} />
            <span>{t('layout.header.profileDetail.myProfile')}</span>
          </a>
        </div>

        <div className="line"></div>

        {items.map((item, index) => {
          return (
            <div key={index} className={`mobile-menu-detail__item-wrapper ${item.className}`}>
              <a
                className="mobile-menu-detail__item-wrapper__item default-item justify-flex-start"
                href={item.path}
              >
                <Icon className="item-icon" component={item.icon} />
                <span>{item.label}</span>
              </a>
            </div>
          );
        })}

        <div className="line"></div>

        <div className="mobile-menu-detail__item-wrapper">
          <a
            className="mobile-menu-detail__item-wrapper__item default-item justify-flex-start"
            href={PATHS_IAO.settings()}
          >
            <Icon className="item-icon" component={SettingIconVer2Component} />
            <span>{t('layout.header.profileDetail.settings')}</span>
          </a>
        </div>

        <div className="mobile-menu-detail__item-wrapper">
          <div
            className="mobile-menu-detail__item-wrapper__item default-item justify-flex-start"
            onClick={() => setIsOpenDisconnect(true)}
          >
            <Icon className="item-icon" component={SignOutIconComponent} />
            <span>{t('layout.header.profileDetail.disconnect')}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderContentWithAuth()}

      <DisconnectWalletModal
        isOpen={isOpenDisconnect}
        onClose={() => setIsOpenDisconnect(false)}
        onConfirm={handleDisconnect}
      />
    </>
  );
};

export default AccountContent;
