import {
  SET_ACCESS_TOKEN,
  SET_CURRENT_CONNECTOR,
  SET_USER_LOGIN_LOADING,
} from '../constants/user';

const initialState = {
  accessToken: '',
  isLoading: false,
  currentConnector: '',
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_USER_LOGIN_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_CURRENT_CONNECTOR:
      return {
        ...state,
        currentConnector: action.payload,
      };
    default:
      return state;
  }
};
