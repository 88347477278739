const SET_BALANCE_ALL = 'SET_BALANCE_ALL';
const SET_EXCHANGE_RATE = 'SET_EXCHANGE_RATE';
const SET_BALANCE_IN_ORDER = 'SET_BALANCE_ASET_BALANCE_IN_ORDERVAILABLE';
const SET_BALANCE_AVAILABLE = 'SET_BALANCE_AVAILABLE';

export const walletAnalytic = {
  SET_BALANCE_ALL,
  SET_EXCHANGE_RATE,
  SET_BALANCE_IN_ORDER,
  SET_BALANCE_AVAILABLE,
};
