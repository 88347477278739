import { MARGIN_MODE } from 'src/constants';
import {
  CLEAR_CURRENT_ACCOUNT,
  CURRENT_ACCOUNT,
  SET_ASSETS,
  GET_MARGIN_MODE,
  GET_PREFERENCE,
  SET_MARGIN_MODE,
} from '../constants/account';

export type IAccount = {
  id: string;
  asset: string;
  balance: string;
  userId: string;
  operationId: string;
  address: string;
  lastFundingTime: string;
};

interface OrderMode {
  marginMode: MARGIN_MODE.CROSS;
  leverage: string;
}

export interface UserSetting {
  limitOrder: boolean;
  marketOrder: boolean;
  stopLimitOrder: boolean;
  stopMarketOrder: boolean;
  traillingStopOrder: boolean;
  postOnlyOrder: boolean;
  takeProfitTrigger: boolean;
  stopLossTrigger: boolean;
  fundingFeeTrigger: boolean;
  fundingFeeTriggerValue: number;
}

export interface CurrentAccountInterface {
  account: string;
  asset: IAccount[];
  loading: boolean;
  orderMode: OrderMode;
  userSetting: UserSetting;
  balanceSpot: {};
}

const initialState: CurrentAccountInterface = {
  account: '',
  asset: [],
  loading: false,
  orderMode: {
    marginMode: MARGIN_MODE.CROSS,
    leverage: '20',
  },
  userSetting: {
    limitOrder: false,
    marketOrder: false,
    stopLimitOrder: false,
    stopMarketOrder: false,
    traillingStopOrder: false,
    postOnlyOrder: false,
    takeProfitTrigger: false,
    stopLossTrigger: false,
    fundingFeeTrigger: false,
    fundingFeeTriggerValue: 0.25,
  },
  balanceSpot: {
    usdt: {
      available_balance: '0',
    },
    usd: {
      available_balance: '0',
    },
    btc: {
      available_balance: '0',
    },
    eth: {
      available_balance: '0',
    },
    bnb: {
      available_balance: '0',
    },
    sol: {
      available_balance: '0',
    },
    matic: {
      available_balance: '0',
    },
    ltc: {
      available_balance: '0',
    },
    atom: {
      available_balance: '0',
    },
    uni: {
      available_balance: '0',
    },
  },
};

const accountReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_CURRENT_ACCOUNT:
      return initialState;

    case CURRENT_ACCOUNT:
      const { account } = action.payload;
      // const accountObj = {
      //   account,
      //   address: account,
      // };
      return { ...state, account };
    case SET_ASSETS:
      const assetsBalance = action.payload;
      return { ...state, asset: assetsBalance };
    case GET_MARGIN_MODE:
      const orderModeRes = action.payload;
      return { ...state, orderMode: orderModeRes };
    case SET_MARGIN_MODE:
      const orderMode = action.payload;
      return { ...state, orderMode };
    case GET_PREFERENCE:
      return {
        ...state,
        userSetting: action.payload,
      };
    default:
      return state;
  }
};

export default accountReducer;
